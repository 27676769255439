import api from "./api";

export const fetchQuantityOfMaterialsOptions = async () => {
  const response = await api.get(`/mm_requests/quantity_of_materials_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchRequesterFieldOptions = async () => {
  const response = await api.get(`/mm_requests/requester_field_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};
