import DuimpRequest from "../@types/services/DuimpRequest";
import api from "./api";

export const createDuimpRequest = async (
  duimpRequest: DuimpRequest,
  file_attachments: File[],
) => {
  const response = await api.post(
    `/duimp_requests`,
    { duimp_request: { mm_request_attributes: duimpRequest } },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  if (response.data.id && file_attachments.length > 0) {
    await addAttachments(file_attachments, response.data.mm_request.id);
  }

  return response.data;
};

const addAttachments = async (file_attachments: File[], id: string) => {
  const formData = new FormData();
  Array.from(file_attachments).forEach((file) => {
    formData.append("file_attachments[]", file);
  });

  await api.postForm(`/duimp_requests/${id}/attach_file`, formData, {
    baseURL: "/vbuyer/api/v2",
  });
};
