import { FC } from "react";


const FilterMultiSelectField: FC = ({ }) => {
  return (
    <div></div>
  )
}

export default FilterMultiSelectField;
