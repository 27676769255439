import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import Actions from "../../organisms/Actions";

interface Props {
  title?: string;
  id?: number;
  background?: string;
  color?: string;
}

const BasicBar = ({
  title,
  id,
  background = "#D9D9D9",
  color = "#000000",
}: Props): ReactElement | null => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="gray">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {id && <Actions entityId={id} options={["invite_supplier"]} />}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default BasicBar;
