import CreatePurchaseOrderByServiceQuotation from "../@types/services/CreatePurchaseOrderByServiceQuotation";
import api from "./api";

interface ListCreatePurchaseOrderByServiceQuotationsParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  filterCompleted?: boolean;
}

interface CreatePurchaseOrderByServiceQuotationResponse {
  total_items: number;
  results: CreatePurchaseOrderByServiceQuotation[] | null;
}

export const listCreatePurchaseOrderByServiceQuotations = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  filterCompleted,
}: ListCreatePurchaseOrderByServiceQuotationsParams): Promise<CreatePurchaseOrderByServiceQuotationResponse> => {
  const response = await api.get<CreatePurchaseOrderByServiceQuotationResponse>(
    "/create_purchase_order_by_service_quotations",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        order,
        completed: filterCompleted,
      },
    },
  );

  return response.data;
};

export const getCreatePurchaseOrderByServiceQuotation = async (
  id: string,
): Promise<CreatePurchaseOrderByServiceQuotation> => {
  const response = await api.get<CreatePurchaseOrderByServiceQuotation>(
    `/create_purchase_order_by_service_quotations/${id}`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const createCreatePurchaseOrderByServiceQuotation = async (
  quotationIds: number[],
): Promise<CreatePurchaseOrderByServiceQuotation> => {
  const response = await api.post<CreatePurchaseOrderByServiceQuotation>(
    `/create_purchase_order_by_service_quotations`,
    {
      service_quotation_ids: quotationIds,
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};
