import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute: FC<{
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactElement | null;
}> = ({
  isAllowed,
  redirectPath = "/users/sign_in", // TODO: Probably the route we want to redirect will be "/", but temporarily changed to "sign_in"
  children = null,
}): ReactElement => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
