import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import ThemeModeContext from "../../contexts/ThemeMode";
import { darkTheme, lightTheme } from "../../theme";

const ThemeModeProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<"light" | "dark">("light");
  const toggleThemeMode = useCallback(() => {
    setThemeMode((prevThemeMode) =>
      prevThemeMode === "light" ? "dark" : "light",
    );
  }, []);

  const theme = useMemo(() => {
    if (themeMode === "dark") {
      return darkTheme;
    }

    return lightTheme;
  }, [themeMode]);

  const providerValues = useMemo(() => {
    return {
      theme,
      toggleThemeMode,
    };
  }, [theme, toggleThemeMode]);

  return (
    <ThemeModeContext.Provider value={providerValues}>
      {children}
    </ThemeModeContext.Provider>
  );
};

export default ThemeModeProvider;
