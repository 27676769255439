import { ThemeOptions } from "@mui/material";

const dark = (): ThemeOptions =>
  ({
    palette: {
      mode: "dark",
      white: {
        main: "#fff",
        contrastText: "#000",
      },
      gray: {
        main: "#D9D9D9",
        contrastText: "#000",
      },
      energyRed: {
        main: "rgb(255, 179, 179)",
        contrastText: "rgb(104, 0, 20)",
      },
      mistBlue: {
        main: "rgb(93, 213, 251)",
        contrastText: "rgb(0, 53, 67)",
      },
      lichenGreen: {
        main: "rgb(111, 219, 169)",
        contrastText: "rgb(0, 56, 36)",
      },
      spruceWood: {
        main: "rgb(255, 183, 121)",
        contrastText: "rgb(76, 39, 0)",
      },
      slateBlue: {
        main: "rgb(142, 205, 255)",
        contrastText: "rgb(0, 52, 79)",
      },
      massGreen: {
        main: "rgb(77, 216, 231)",
        contrastText: "rgb(0, 54, 59)",
      },
      heritageRed: {
        main: "rgb(255, 178, 183)",
        contrastText: "rgb(103, 0, 27)",
      },
    },
  }) as ThemeOptions;

export default dark;
