import { Vendor } from "../@types/services";
import api from "./api";

export type VendorsFilter = {
  name: string;
  email: string;
  tax_number: string;
  sap_code: string;
};

interface VendorsFetch {
  page?: number;
  itemsPerPage?: number;
  filters?: VendorsFilter;
}

interface VendorsResponse {
  total_items?: number;
  results: Vendor[];
}

export const fetchVendors = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: VendorsFetch): Promise<VendorsResponse> => {
  const response = await api.get<VendorsResponse>("/vendors", {
    baseURL: "/vsupplierregistration/api/v2",
    params: {
      page,
      items_per_page: itemsPerPage,
      ...filters,
    },
  });

  return response.data;
};

export const fetchVendor = async (id: string): Promise<Vendor> => {
  const response = await api.get<Vendor>(`/vendors/${id}`, {
    baseURL: "/vsupplierregistration/api/v2",
  });

  return response.data;
};
