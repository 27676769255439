import {
  Autocomplete,
  Box,
  CircularProgress,
  Pagination,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import {
  FC,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Plant, PurchaseGroup } from "../../@types/services";
import ServiceQuotation from "../../@types/services/ServiceQuotation";
import { Filter } from "../../components/molecules/Filter";
import ListServiceQuotations from "../../components/molecules/ListServiceQuotations";
import { PlantsFilter, fetchPlants } from "../../services/plant";
import {
  PurchaseGroupsFilter,
  fetchPurchaseGroups,
} from "../../services/purchaseGroup";
import {
  ServiceQuotationFilter,
  fetchServiceQuotations,
} from "../../services/serviceQuotations";

interface FilterPlantsProps {
  selectedPlants: Plant[];
  setSelectedPlants: React.Dispatch<React.SetStateAction<Plant[]>>;
}

const FilterPlants: FC<FilterPlantsProps> = ({
  selectedPlants,
  setSelectedPlants,
}): ReactElement => {
  const rowsPerPage = 100;
  const [page, setPage] = useState(1);
  const [openOptions, setOpenOptions] = useState(false);
  const [plants, setPlants] = useState<Plant[]>([]);
  const loading = openOptions && plants.length === 0;
  const handleFetchPlants = useCallback(
    async (pageNumber: number, filters?: PlantsFilter) => {
      if (filters && filters.name.length >= 0) {
        try {
          const response = await fetchPlants({
            page: pageNumber,
            itemsPerPage: rowsPerPage,
            filters,
          });

          const results = response.results || [];

          if (Array.isArray(response.results) && response.results.length > 0) {
            setPlants(results);
            setPage(pageNumber);
          } else {
            setPlants([]);
            enqueueSnackbar(i18next.t("plants.notFound"));
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar(i18next.t("plants.fetchError"));
        }
      }
    },
    [enqueueSnackbar, rowsPerPage],
  );
  const handleOptionSelected = async (selectedOptions: Plant[]) => {
    setPlants([]);
    setPage(1);
    setSelectedPlants(selectedOptions);
  };
  const handleOptionRemoved = (removedPlant: Plant) => {
    setSelectedPlants((prevSelectedPlants) =>
      prevSelectedPlants.filter(
        (existingPlant) => existingPlant.id !== removedPlant.id,
      ),
    );
  };

  return (
    <Autocomplete
      multiple
      id="asynchronous-demo"
      sx={{ width: "50%", padding: "12px" }}
      open={openOptions}
      onOpen={() => {
        setOpenOptions(true);
      }}
      onClose={() => {
        setOpenOptions(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.plant_number} - ${option.name}`}
      value={selectedPlants}
      options={plants}
      noOptionsText={i18next.t("serviceQuotation.placeholder.enterSearch")}
      onInputChange={(event, value) => {
        handleFetchPlants(1, {
          name: value,
          description: "",
        });
      }}
      onChange={(event, value, reason, details) => {
        if (reason === "selectOption") {
          handleOptionSelected(value);
        } else if (reason === "removeOption") {
          if (details?.option) {
            handleOptionRemoved(details?.option);
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          key={params.inputProps.id}
          label={i18next.t("plant")}
          placeholder={i18next.t("serviceQuotation.placeholder.enterPlant")}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading &&
                params?.inputProps.value &&
                (params.inputProps.value as string).length >= 0 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
interface FilterPurchaseGroupsProps {
  selectedPurchaseGroups: PurchaseGroup[];
  setSelectedPurchaseGroups: React.Dispatch<
    React.SetStateAction<PurchaseGroup[]>
  >;
}

const FilterPurchaseGroups: FC<FilterPurchaseGroupsProps> = ({
  selectedPurchaseGroups,
  setSelectedPurchaseGroups,
}): ReactElement => {
  const rowsPerPage = 100;
  const [page, setPage] = useState(1);
  const [openOptions, setOpenOptions] = useState(false);
  const [purchaseGroups, setPurchaseGroups] = useState<PurchaseGroup[]>([]);
  const loading = openOptions && purchaseGroups.length === 0;
  const handleFetchPurchaseGroups = useCallback(
    async (pageNumber: number, filters?: PurchaseGroupsFilter) => {
      if (filters && filters.name.length >= 0) {
        try {
          const response = await fetchPurchaseGroups({
            page: pageNumber,
            itemsPerPage: rowsPerPage,
            filters,
          });

          const results = response.results || [];

          if (Array.isArray(response.results) && response.results.length > 0) {
            setPurchaseGroups(results);
            setPage(pageNumber);
          } else {
            setPurchaseGroups([]);
            enqueueSnackbar(i18next.t("purchaseGroups.notFound"));
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar(i18next.t("purchaseGroups.fetchError"));
        }
      }
    },
    [enqueueSnackbar, rowsPerPage],
  );
  const handleOptionSelected = async (selectedOptions: PurchaseGroup[]) => {
    setPurchaseGroups([]);
    setPage(1);
    setSelectedPurchaseGroups((prevSelectedPurchaseGroups) => {
      const novosElementos = selectedOptions.filter(
        (selectedOption) =>
          !prevSelectedPurchaseGroups.some(
            (existingOption) => existingOption.id === selectedOption.id,
          ),
      );

      return [...prevSelectedPurchaseGroups, ...novosElementos];
    });
  };
  const handleOptionRemoved = (removedPurchaseGroup: PurchaseGroup) => {
    setSelectedPurchaseGroups((prevSelectedPurchaseGroups) =>
      prevSelectedPurchaseGroups.filter(
        (existingPurchaseGroup) =>
          existingPurchaseGroup.id !== removedPurchaseGroup.id,
      ),
    );
  };

  return (
    <Autocomplete
      multiple
      id="asynchronous-demo"
      sx={{ width: "50%", padding: "12px" }}
      open={openOptions}
      onOpen={() => {
        setOpenOptions(true);
      }}
      onClose={() => {
        setOpenOptions(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name} - ${option.description}`}
      value={selectedPurchaseGroups}
      options={purchaseGroups}
      noOptionsText={i18next.t("serviceQuotation.placeholder.enterSearch")}
      onInputChange={(event, value) => {
        handleFetchPurchaseGroups(1, {
          name: value,
          description: "",
        });
      }}
      onChange={(event, value, reason, details) => {
        if (reason === "selectOption") {
          handleOptionSelected(value);
        } else if (reason === "removeOption") {
          if (details?.option) {
            handleOptionRemoved(details?.option);
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          key={params.inputProps.id}
          label={i18next.t("purchase_groups")}
          placeholder={i18next.t(
            "serviceQuotation.placeholder.enterPurchaseGroup",
          )}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading &&
                params?.inputProps.value &&
                (params.inputProps.value as string).length >= 0 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const ServiceQuotations: FC = (): ReactElement => {
  const [appliedFilters, setAppliedFilters] = useState<ServiceQuotationFilter>(
    {},
  );
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedPurchaseGroups, setSelectedPurchaseGroups] = useState<
    PurchaseGroup[]
  >([]);
  const [selectedPlants, setSelectedPlants] = useState<Plant[]>([]);
  const [serviceQuotations, setServiceQuotations] = useState<
    ServiceQuotation[]
  >([]);
  const { control, handleSubmit, reset } = useForm();
  const [searchParams] = useSearchParams();

  const handleFetchServiceQuotations = useCallback(
    async (page: number, filters?: ServiceQuotationFilter) => {
      setLoading(true);

      const filtersToApply = filters || appliedFilters;

      if (searchParams.get("tab") === "open") {
        filtersToApply.status_name = ["Waiting Send"];
      } else if (searchParams.get("tab") === "ongoing") {
        filtersToApply.status_name = [
          "Waiting Offers",
          "Waiting Approval",
          "Checking Proposal",
          "Checking Lines Changes",
          "Waiting Change of Details",
          "Issuing Purchase Order",
        ];
      } else if (searchParams.get("tab") === "finished") {
        filtersToApply.status_name = ["Purchase Order Created", "Finished"];
      }

      try {
        const sq = await fetchServiceQuotations({
          page,
          itemsPerPage: rowsPerPage,
          filters: filtersToApply,
        });
        setServiceQuotations(sq.results || []);
        setTotalRows(sq.total_items || 0);
      } catch (error) {
        console.error("Error fetching service quotations:", error);
        enqueueSnackbar(i18next.t("serviceQuotation.fetchError"));
      } finally {
        setLoading(false);
      }
    },
    [rowsPerPage, appliedFilters, searchParams, enqueueSnackbar],
  );

  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ): void => {
    setCurrentPage(() => value);
  };

  const handleFilterSubmit: SubmitHandler<ServiceQuotationFilter> = (data) => {
    const newFilters: ServiceQuotationFilter = {
      ...data,
      purchase_group_ids: selectedPurchaseGroups.map((pg) => pg.id),
      plant_ids: selectedPlants.map((plant) => plant.id),
    };

    setAppliedFilters(newFilters);
    setCurrentPage(1);
    handleFetchServiceQuotations(1, newFilters);
  };

  const handleFilterClear = (): void => {
    reset();
    setAppliedFilters({});
    setSelectedPurchaseGroups([]);
    setSelectedPlants([]);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage !== 1 || Object.keys(appliedFilters).length > 0) {
      handleFetchServiceQuotations(currentPage, appliedFilters);
    } else {
      handleFetchServiceQuotations(1, {});
    }
  }, [appliedFilters, currentPage, handleFetchServiceQuotations]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1,
        justifyContent: "center",
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit(handleFilterSubmit)}>
          <Filter.Root>
            <Filter.TextField
              control={control}
              type="number"
              label={i18next.t("serviceQuotation.identifier")}
              name="identifier"
            />
            <Filter.TextField
              control={control}
              // type="number"
              label={i18next.t("serviceQuotation.prNumber")}
              name="pr_number"
              inputProps={{
                inputMode: "numeric",
                pattern: "\\d{10,10}",
                maxLength: 10,
              }}
            />
            <FilterPurchaseGroups
              selectedPurchaseGroups={selectedPurchaseGroups}
              setSelectedPurchaseGroups={setSelectedPurchaseGroups}
            />
            <FilterPlants
              selectedPlants={selectedPlants}
              setSelectedPlants={setSelectedPlants}
            />
            <Filter.Actions>
              <Filter.Action
                text={i18next.t("apply")}
                type="submit"
                disabled={loading}
              />
              <Filter.Action
                text={i18next.t("clean")}
                onClick={handleFilterClear}
                sxClass={{
                  color: "red",
                  "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                }}
              />
            </Filter.Actions>
          </Filter.Root>
        </form>
        <ListServiceQuotations
          serviceQuotations={serviceQuotations}
          loading={loading}
        />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalRows / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceQuotations;
