import { CircularProgress } from "@mui/material";
import { FC, ReactElement } from "react";

const CircularIndeterminate: FC = (): ReactElement => {
  return (
    <div>
      <CircularProgress size={100} color="secondary" />
    </div>
  );
};

export default CircularIndeterminate;
