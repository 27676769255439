/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { mdiDeleteOutline, mdiPlusBox } from "@mdi/js";
import { Icon } from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikErrors } from "formik";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import Currency from "../../@types/services/Currency";
import MeasureUnit from "../../@types/services/MeasureUnit";
import Proposal from "../../@types/services/Proposal";
import ProposalLine from "../../@types/services/ProposalLine";
import ServiceNegotiation from "../../@types/services/ServiceNegotiation";
import NumericFormatField from "../../components/atoms/NumericFormatField";
import DownloadProposals from "../../components/molecules/DownloadProposals";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import getCurrencies from "../../services/currency";
import getMeasureUnits, {
  MeasureUnitsFetch,
} from "../../services/measureUnits";
import { editProposal, fetchProposal } from "../../services/proposals";
import { getServiceNegotiation } from "../../services/serviceNegotiations";

const ProposalLineSchema = z.object({
  short_text: z.string(),
  quantity: z.coerce.number().min(1),
  gross_price: z.coerce.number().min(1),
});

const ProposalSchema = z.object({
  delivery_date: z.string().date(),
  proposal_lines: z.array(ProposalLineSchema),
});

const EditProposal: FC = () => {
  const { serviceNegotiationId, proposalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [serviceNegotiation, setServiceNegotiation] =
    useState<ServiceNegotiation>();
  const [proposal, setProposal] = useState<Proposal>();
  const [measureUnits, setMeasureUnits] = useState<MeasureUnit[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [formattedDate, setFormattedDate] = useState<string>();
  const navigate = useNavigate();

  const resetInputOrder = (proposalLines: ProposalLine[]) => {
    return proposalLines.map((line, index) => ({
      ...line,
      position: (index + 1) * 10,
    }));
  };

  const handleFetchMeasureUnits = useCallback(
    async (names: MeasureUnitsFetch) => {
      getMeasureUnits(names).then((resource) => {
        setMeasureUnits(resource);
      });
    },
    [],
  );

  const handleFetchCurrencies = useCallback(async () => {
    getCurrencies().then((resource) => {
      setCurrencies(resource);
    });
  }, []);

  const handleFetchProposal = useCallback(async (id: string) => {
    await fetchProposal(id).then((resource) => {
      setProposal(resource);
      if (!resource.delivery_date) {
        return;
      }
      const deliveryDate = new Date(resource.delivery_date);
      const year = deliveryDate.getFullYear();
      const month = String(deliveryDate.getMonth() + 1).padStart(2, "0");
      const day =
        deliveryDate.getUTCDate() < 10
          ? `0${deliveryDate.getUTCDate()}`
          : String(deliveryDate.getUTCDate());

      setFormattedDate(`${year}-${month}-${day}`);
    });
  }, []);

  const handleFetchServiceNegotiation = useCallback(
    async (id: number) => {
      setLoading(() => true);
      await getServiceNegotiation(id)
        .then((resource) => {
          setServiceNegotiation(() => resource);
          if (resource.proposal) {
            handleFetchProposal(resource.proposal.id);
          }
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [handleFetchProposal],
  );

  useEffect(() => {
    if (!serviceNegotiationId) {
      setNotFound(() => true);
      return;
    }

    handleFetchMeasureUnits({ names: ["DAY", "MON", "LE", "EA", "H", "PC"] });
    handleFetchCurrencies();
    handleFetchServiceNegotiation(parseInt(serviceNegotiationId, 10));
  }, [
    handleFetchServiceNegotiation,
    serviceNegotiationId,
    handleFetchMeasureUnits,
    handleFetchCurrencies,
  ]);

  if (!serviceNegotiation && !proposal) {
    return <LoadingCentral />;
  }

  return (
    <Container
      sx={{
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          display: "flex",
          border: "1px solid #d9d9d9",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {proposal && (
          <Formik
            initialValues={proposal}
            validationSchema={toFormikValidationSchema(ProposalSchema)}
            onSubmit={(values) => {
              setLoading(() => true);
              if (!serviceNegotiationId || !proposalId) {
                return;
              }

              editProposal(values, proposal.id).then(() => {
                navigate(
                  `/vbuyer/service_negotiations/${serviceNegotiationId}`,
                );
                enqueueSnackbar("Proposta editada com sucesso!");
              });
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "3px",
                  padding: "20px",
                  marginBottom: "10px",
                }}
              >
                <h2>{i18next.t("newProposal.titleEdit")}</h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <h3 style={{ width: "100%" }}>
                    {i18next.t("newProposal.serviceQuotation")}
                    {" #"}
                    {serviceNegotiation?.service_quotation?.identifier}
                  </h3>
                  <div>
                    {proposal && <DownloadProposals proposal={proposal} />}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    sx={{ width: "100%" }}
                    value={
                      serviceNegotiation?.service_quotation
                        ?.short_description || ""
                    }
                    disabled
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-size-small"
                    name="delivery_date"
                    label={i18next.t("newProposal.deliveryDate")}
                    type="date"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={formattedDate}
                    onChange={handleChange}
                    error={!!errors.delivery_date}
                    helperText={errors.delivery_date}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "35px",
                    margin: "20px 0px",
                  }}
                >
                  <FormControl style={{ width: "20%", marginRight: "5px" }}>
                    <InputLabel id="select-label">
                      {i18next.t("newProposal.currency")}
                    </InputLabel>
                    <Field
                      as={Select}
                      name="currency"
                      labelId="select-label"
                      id="select"
                      label="currency"
                      value={values.currency.id || values.currency}
                      onChange={handleChange}
                      required
                    >
                      {currencies.map((cur) => (
                        <MenuItem key={cur.id} value={cur.id}>
                          {` ${cur.name} - `}
                          {cur.unit}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>

                  <Field
                    name="technicalProposal"
                    type="file"
                    label={i18next.t("newProposal.technicalProposal")}
                    component={TextField}
                    onChange={(event: {
                      currentTarget: { files: (FileList | undefined)[] };
                    }) => {
                      const file = event.currentTarget.files[0];
                      handleChange(event);
                      setFieldValue("technicalProposal", file);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ multiple: false }}
                  />

                  <Field
                    name="commercialProposal"
                    type="file"
                    label={i18next.t("newProposal.commercialProposal")}
                    component={TextField}
                    onChange={(event: {
                      currentTarget: { files: (FileList | undefined)[] };
                    }) => {
                      const file = event.currentTarget.files[0];
                      handleChange(event);
                      setFieldValue("commercialProposal", file);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ multiple: false }}
                  />
                </div>

                <FieldArray name="proposal_lines">
                  {({ insert, remove, push }) => (
                    <div>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "40%" }}>
                                {i18next.t("newProposal.shortText")}
                              </TableCell>
                              <TableCell align="center">
                                {i18next.t("newProposal.quantity")}
                              </TableCell>
                              <TableCell align="center">
                                {i18next.t("newProposal.measureUnit")}
                              </TableCell>
                              <TableCell align="center">
                                {i18next.t("newProposal.price")}
                              </TableCell>
                              <TableCell align="center"> # </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.proposal_lines.map((line, index) => (
                              <TableRow key={line.id}>
                                <TableCell sx={{ width: "40%" }}>
                                  <Tooltip
                                    title={
                                      (
                                        errors.proposal_lines as FormikErrors<ProposalLine>[]
                                      )?.[index]?.short_text || ""
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <TextField
                                      sx={{ width: "100%" }}
                                      name={`proposal_lines[${index}].short_text`}
                                      size="small"
                                      value={line.short_text}
                                      inputProps={{ maxLength: 40 }}
                                      onChange={handleChange}
                                      error={
                                        !!(
                                          errors.proposal_lines as FormikErrors<ProposalLine>[]
                                        )?.[index]?.short_text
                                      }
                                      required
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <Tooltip
                                    title={
                                      (
                                        errors.proposal_lines as FormikErrors<ProposalLine>[]
                                      )?.[index]?.quantity
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <TextField
                                      type="number"
                                      name={`proposal_lines[${index}].quantity`}
                                      size="small"
                                      inputProps={{ step: 0.01, min: 0.01 }}
                                      value={line.quantity}
                                      onChange={handleChange}
                                      error={
                                        !!(
                                          errors.proposal_lines as FormikErrors<ProposalLine>[]
                                        )?.[index]?.quantity
                                      }
                                      required
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <FormControl style={{ width: "100%" }}>
                                    <InputLabel id={`select-label-${index}`}>
                                      {i18next.t("newProposal.measureUnit")}
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`proposal_lines[${index}].measure_unit`}
                                      size="small"
                                      labelId={`select-label-${index}`}
                                      id={`proposal_lines[${index}].measure_unit`}
                                      label="measure_unit"
                                      value={
                                        line?.measure_unit?.id ||
                                        line?.measure_unit
                                      }
                                      onChange={handleChange}
                                      required
                                    >
                                      {measureUnits.map((unit) => (
                                        <MenuItem key={unit.id} value={unit.id}>
                                          {unit.name}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Tooltip
                                    title={
                                      (
                                        errors.proposal_lines as FormikErrors<ProposalLine>[]
                                      )?.[index]?.gross_price
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <TextField
                                      name={`proposal_lines[${index}].gross_price`}
                                      size="small"
                                      value={line.gross_price}
                                      onChange={handleChange}
                                      InputProps={{
                                        inputComponent:
                                          NumericFormatField as any,
                                      }}
                                      error={
                                        !!(
                                          errors.proposal_lines as FormikErrors<ProposalLine>[]
                                        )?.[index]?.gross_price
                                      }
                                      required
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <Tooltip placement="right" title="Delete">
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        remove(index);
                                        setFieldValue(
                                          "proposal_lines",
                                          resetInputOrder(
                                            values.proposal_lines.filter(
                                              (_, idx) => idx !== index,
                                            ),
                                          ),
                                        );
                                      }}
                                      style={{
                                        marginLeft: "8px",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      <Icon path={mdiDeleteOutline} size={1} />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell align="center" colSpan={5}>
                                <Tooltip
                                  placement="top"
                                  title={i18next.t(
                                    "newProposal.button.addNewDetailLine",
                                  )}
                                >
                                  <LoadingButton
                                    onClick={() =>
                                      push({
                                        position:
                                          (values.proposal_lines.length + 1) *
                                          10,
                                      })
                                    }
                                    style={{
                                      marginTop: "8px",
                                    }}
                                    size="small"
                                    color="info"
                                    variant="contained"
                                    loading={loading}
                                    loadingPosition="center"
                                  >
                                    <Icon path={mdiPlusBox} size={1} />
                                  </LoadingButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          style={{
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                          color="success"
                          variant="contained"
                          loading={loading}
                          loadingPosition="center"
                        >
                          {i18next.t("newProposal.button.send")}
                        </LoadingButton>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </Container>
  );
};

export default EditProposal;
