import { useContext } from "react";
import ThemeModeContext, { ThemeModeContextData } from "../contexts/ThemeMode";

const useThemeMode = (): ThemeModeContextData => {
  const context = useContext(ThemeModeContext);

  if (!context) {
    throw new Error("useThemeMode must be used within an ThemeModeProvider");
  }

  return context;
};

export default useThemeMode;
