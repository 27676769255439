import { mdiEmailOutline, mdiEye, mdiEyeOff, mdiKey } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import * as yup from "yup";
import LogoRed from "../../images/equinor-logo-red-horizontal.svg";
import useAuth from "../hooks/useAuth";

const Login: FC = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { signIn, user } = useAuth();
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/react/demo", { replace: true });
    }
  }, [user, navigate]);

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(i18next.t("login.invalidEmail"))
      .required(i18next.t("login.requiredEmail")),
    password: yup
      .string()
      .min(8, i18next.t("login.invalidPassword"))
      .required(i18next.t("login.requiredPassword")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const token = recaptchaRef.current?.getValue();

      if (
        !token &&
        !(window.environment === "test" || window.environment === "development")
      ) {
        enqueueSnackbar(i18next.t("login.invalidBotVerification"));
        return;
      }

      setLoading(() => true);

      if (values.email && values.password) {
        const { email, password } = values;
        try {
          const result = await signIn({
            email,
            password,
            token: token ?? "",
          });

          navigate("/redirect/rails/home");
          // window.location.href = "/";
        } catch {
          recaptchaRef.current?.reset();
          setLoading(() => false);
        }
      }
    },
  });

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ maxWidth: 440 }}>
        <CardContent>
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                pb: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={LogoRed} alt="logo" style={{ width: "60%" }} />
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                fullWidth
                margin="normal"
                id="email"
                placeholder={i18next.t("login.email")}
                InputProps={{
                  startAdornment: (
                    <Icon
                      path={mdiEmailOutline}
                      size={1}
                      style={{ color: "grey", marginRight: "5px" }}
                    />
                  ),
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                id="password"
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                sx={{ mb: 1 }}
                InputProps={{
                  startAdornment: (
                    <Icon
                      path={mdiKey}
                      size={1}
                      style={{ color: "grey", marginRight: "5px" }}
                    />
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => setShowPassword(() => true)}
                        onMouseUp={() => setShowPassword(() => false)}
                        onMouseOutCapture={() => setShowPassword(() => false)}
                        edge="end"
                      >
                        {showPassword ? (
                          <Icon path={mdiEye} size={1} />
                        ) : (
                          <Icon path={mdiEyeOff} size={1} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={i18next.t("login.password")}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="normal"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
                />
              </Box>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                loading={loading}
                loadingPosition="center"
              >
                {i18next.t("login.signIn")}
              </LoadingButton>

              <h4
                style={{
                  margin: "0px",
                  width: "100%",
                  textAlign: "center",
                  color: "grey",
                }}
              >
                {i18next.t("login.or")}
              </h4>
            </form>

            <form
              action={process.env.REACT_APP_OMNIAUTH_AZURE_URL ?? ""}
              method="post"
              onSubmit={() => setLoading(() => true)}
            >
              <input type="hidden" name="_method" value="post" />
              <input
                type="hidden"
                name="authenticity_token"
                value={getCookie("SELLTO-CSRF-TOKEN")}
              />
              <LoadingButton
                type="submit"
                color="energyRed"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                loading={loading}
                loadingPosition="center"
              >
                {i18next.t("login.equinor")}
              </LoadingButton>
            </form>

            <hr />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MuiLink href="/users/sign_up" underline="hover" color="inherit">
                {i18next.t("login.createAccount")}
              </MuiLink>

              <div
                id="dot"
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "10px",
                  background: "#606060",
                }}
              />
              <MuiLink
                href="/users/password/new"
                underline="hover"
                color="inherit"
              >
                {i18next.t("login.forgotPassword")}
              </MuiLink>
            </div>

            <div style={{ display: "flex" }}>
              <MuiLink
                href="/users/confirmation/new"
                sx={{ width: "100%", textAlign: "center" }}
                color="inherit"
                underline="hover"
              >
                {i18next.t("login.resendConfirmationInstructions")}
              </MuiLink>
            </div>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
