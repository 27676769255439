import { Box } from "@mui/material";
import i18next from "i18next";
import PageTitle from "../../components/atoms/PageTitle";
import DuimpRequestForm from "../../components/molecules/DuimpRequestForm";

const DuimpRequest = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageTitle title={i18next.t("duimpRequests.formTitle")} />
      <DuimpRequestForm />
    </Box>
  );
};

export default DuimpRequest;
