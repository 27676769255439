import { mdiAccountDetails } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { Vendor } from "../../../@types/Vendor";

interface ListVendorProps {
  vendors: Vendor[];
}

const ListVendors: FC<ListVendorProps> = ({ vendors }): ReactElement => {
  return (
    <Box sx={{ p: 2, borderRadius: "10px", border: "5px" }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Country</TableCell>
              <TableCell align="left">Sapcode</TableCell>
              <TableCell align="left">CNPJ</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Segment</TableCell>
              <TableCell align="center">#</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendors?.length > 0 &&
              vendors.map((value: Vendor) => (
                <TableRow
                  key={value.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{ width: `30%` }}
                  >{`${value.fullvendorname}`}</TableCell>
                  <TableCell
                    sx={{ width: `10%` }}
                  >{`${value.email}`}</TableCell>
                  <TableCell
                    sx={{ width: `5%` }}
                  >{`${value.country}`}</TableCell>
                  <TableCell
                    sx={{ width: `5%` }}
                  >{`${value.sap_code || ""}`}</TableCell>
                  <TableCell
                    sx={{ width: `10%` }}
                  >{`${value.taxnumber1}`}</TableCell>
                  <TableCell
                    sx={{ width: `10%` }}
                  >{`${value.category || ""}`}</TableCell>
                  <TableCell
                    sx={{ width: `25%` }}
                  >{`${value.segment || ""}`}</TableCell>
                  <TableCell sx={{ width: `5%` }} align="center">
                    {/* Substituir o Button quando migrar a tela de detalhes para react */}
                    {/* <Button component={Link} to={`/vsupplierregistration/vendors/${value.id}`}> */}
                    <Button
                      style={{ backgroundColor: "white", color: "black" }}
                      component="a"
                      href={`/vsupplierregistration/vendors/${value.id}`}
                    >
                      <Icon size={1} path={mdiAccountDetails} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListVendors;
