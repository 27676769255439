import { mdiAccountCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton, ListItem, ListItemText, Skeleton } from "@mui/material";
import { FC, ReactElement } from "react";

const ListMaterials: FC<{ materials: any }> = ({ materials }): ReactElement => {
  return (
    <div>
      {materials.length > 0 ? (
        materials.map((value: any) => (
          <ListItem
            key={value.id}
            disableGutters
            secondaryAction={
              <IconButton aria-label="comment">
                <Icon size={0.75} path={mdiAccountCircle} />
              </IconButton>
            }
          >
            <ListItemText primary={`Line item ${value.id}`} />
          </ListItem>
        ))
      ) : (
        <>
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
          <Skeleton animation="wave" height={90} />
        </>
      )}
    </div>
  );
};

export default ListMaterials;
