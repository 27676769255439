import IChatMessage from "../@types/services/IChatMessage";
import api from "./api";

export const fetchChatMessages = async (
  id: string,
): Promise<IChatMessage[]> => {
  const response = await api.get("/chat_messages", {
    baseURL: "/vbuyer/api/v2",
    params: {
      id,
    },
  });

  return response.data;
};

const createChatMessage = async (
  message: string,
  chatId: string,
): Promise<IChatMessage> => {
  const response = await api.post<IChatMessage>(
    "/chat_messages",
    { message, chat_id: chatId },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export default createChatMessage;

export const addReadMessage = async (chatId: string): Promise<void> => {
  await api.post(
    "/chat_messages/add_read_messages",
    { chat_id: chatId },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
};
