import { Box } from "@mui/material";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import Help from "../../@types/services/Help";
import ListHelps from "../../components/molecules/ListHelps";
import { fetchHelps } from "../../services/helps";

export const Helps: FC<{ type_help: string }> = ({
  type_help,
}): ReactElement => {
  const [helps, setHelps] = useState<Help[]>([]);
  const [helpsPerPage] = useState(25);

  const handleFetchHelps = useCallback(
    async (page: number, helpsPerPage: number) => {
      await fetchHelps(1, type_help).then(({ results }) => {
        setHelps(results.slice(page, helpsPerPage));
      });
    },
    [],
  );

  useEffect(() => {
    handleFetchHelps(0, helpsPerPage);
  }, [handleFetchHelps, helpsPerPage]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1,
        justifyContent: "center",
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 1,
        gridTemplateRows: "auto",
        gridTemplateAreas: `
            "main"
          `,
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ gridArea: "main" }}>
        <ListHelps helps={helps} type_help={type_help} />
      </Box>
    </Box>
  );
};
