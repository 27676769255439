import { Card, CardContent, Grid, Typography } from "@mui/material";
import i18next from "i18next";
import { FC } from "react";
import PurchaseRequisition from "../../../@types/services/PurchaseRequisition";

interface MaintenanceOrderProps {
  purchaseRequisition: PurchaseRequisition;
}

const MaintenanceOrder: FC<MaintenanceOrderProps> = ({
  purchaseRequisition,
}) => {
  return (
    <Card variant="outlined" square>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t("serviceQuotation.plantMaintenanceOrder.orderNumber")}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.order_number || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t("serviceQuotation.plantMaintenanceOrder.orderType")}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.order_type || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t("serviceQuotation.plantMaintenanceOrder.description")}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.description || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t("serviceQuotation.plantMaintenanceOrder.revision")}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.revision_code || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t("serviceQuotation.plantMaintenanceOrder.requiredEnd")}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.required_end || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t(
                "serviceQuotation.plantMaintenanceOrder.basicStartDate",
              )}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.basic_start_date ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t(
                "serviceQuotation.plantMaintenanceOrder.mainWorkCenter",
              )}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.main_work_center ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="div">
              {i18next.t("serviceQuotation.plantMaintenanceOrder.cmrIndicator")}
            </Typography>
            <Typography variant="body2" component="div">
              {purchaseRequisition.plant_maintenance_order?.cmr_indicator ||
                "-"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MaintenanceOrder;
