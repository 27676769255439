import { Theme } from "@mui/material";
import { createContext } from "react";
import { lightTheme } from "../../theme";

export interface ThemeModeContextData {
  theme: Theme;
  toggleThemeMode(): void;
}

const ThemeModeContext = createContext<ThemeModeContextData>({
  theme: lightTheme,
  toggleThemeMode: () => {},
});

export default ThemeModeContext;
