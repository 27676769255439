import { Accordion, AccordionDetails, AccordionSummary, Collapse, List, ListItemButton, ListItemText, ListSubheader, MenuItem, Select, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import {
  mdiChevronDown,
  mdiHelpCircle
} from "@mdi/js";
import Icon from "@mdi/react";
import i18next from "i18next";

type OpenCollapseState = Record<string, boolean>; // Defina o tipo para openCollapse

const ListHelps: FC<{ helps: any, type_help: string }> = ({ helps, type_help }): ReactElement => {
  const [openCollapse, setOpenCollapse] = useState<OpenCollapseState>({});
  const [groupedHelps, setGroupedHelps] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState('--- Selecione uma opção ---');
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [value, setValue] = useState(0);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleToggle = (id: number) => {
    setOpenCollapse({
      ...openCollapse,
      [id]: !openCollapse[id] || false,
    });
  };

  const handleSelectChange = (event: { target: { value: any; }; }) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const selectedVariantAnswer = helps.reduce((result: any, help: { help_answers: any[]; }) => {
      const answer = help.help_answers.find((answer) =>
        answer.help_answer_variants.some((variant: { value: any; }) => variant.value === selectedValue)
      );
      if (answer) {
        return answer.answer;
      }
      return result;
    }, '');

    setSelectedAnswer(selectedVariantAnswer);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleGroupedHelps = () => {
    const groupedHelps = helps.reduce((groups: any, help: any) => {
      const theme = help.help_theme.theme;
      if (!groups[theme]) {
        groups[theme] = [];
      }
      groups[theme].push(help);
      return groups;
    }, {});
    setGroupedHelps(groupedHelps);
  }

  useEffect(() => {
    if (type_help != 'general') {
      handleGroupedHelps();
    }
  }, [handleGroupedHelps]);

  return (
    <div>
      <Typography
        sx={{ p: 3, justifyContent: "center", textAlign: "left", fontSize: "24px" }}
        paragraph
      >
        <Icon size={1} path={mdiHelpCircle} /> {i18next.t(`helps.${type_help === 'general' ? "title" : "procedures"}`)}
      </Typography>
      <div>
        {type_help == 'general' ? (
          helps.map((help: any) => (
            <Accordion expanded={expanded === help.id} onChange={handleChange(help.id)}>
              <AccordionSummary
                expandIcon={<Icon path={mdiChevronDown} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                  {help.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ pl: 2 }}>
                  <div dangerouslySetInnerHTML={{ __html: help.help_answers[0].answer }} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div>
            <Tabs value={value} onChange={handleTabChange} centered>
              {Object.keys(groupedHelps).map((theme, index) => (
                <Tab key={index} label={theme} />
              ))}
            </Tabs>
            {Object.keys(groupedHelps).map((theme, index) => (
              <div key={index}>
                {value === index && (
                  <div>
                    {groupedHelps[theme].map((help: any) => (
                      <Accordion expanded={expanded === help.id} onChange={handleChange(help.id)}>
                        <AccordionSummary
                          expandIcon={<Icon path={mdiChevronDown} />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            {help.question}
                          </Typography>
                        </AccordionSummary>

                        {help.id === 18 ? (
                          <AccordionDetails>
                            <div className="flex" style={{ alignItems: 'center' }}>
                              <span style={{ marginRight: '10px' }}>Escolha o CNPJ:</span>
                              <Select
                                value={selectedOption}
                                onChange={handleSelectChange}
                              >
                                <MenuItem key={0} value={'--- Selecione uma opção ---'}>
                                  --- Selecione uma opção ---
                                </MenuItem>
                                {
                                  help.help_answers.map((answer: any) => (
                                    answer.help_answer_variants.map((variant: any, variantIndex: number) => (
                                      <MenuItem key={variantIndex} value={variant.value}>
                                        {variant.value}
                                      </MenuItem>
                                    ))
                                  ))
                                }
                              </Select>
                            </div>
                            <Typography sx={{ pl: 2 }}>
                              <div dangerouslySetInnerHTML={{ __html: selectedAnswer || help.help_answers[0].answer }} />
                            </Typography>
                          </AccordionDetails>

                        ) : (

                          <AccordionDetails>
                            <Typography sx={{ pl: 2 }}>
                              <div dangerouslySetInnerHTML={{ __html: help.help_answers[0].answer }} />
                            </Typography>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListHelps;
