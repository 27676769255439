import { useEffect } from "react";
import * as CookieConsentApi from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

const CookieConsent = (): null => {
  useEffect(() => {
    CookieConsentApi.run({
      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {
          enabled: true,
          readOnly: false,
          services: {
            ga: {
              label: "Google Analytics",
              cookies: [
                {
                  name: /^(_ga|_gid)/,
                },
              ],
            },
          },
        },
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                'We use cookies to improve your user experience, to collect statistics for analyzing how the website is used and for customized advertising in social media and other websites you visit. By clicking "Accept all", you are giving your consent to the use of cookies by Equinor Sell To and third parties on this website. You can also choose your preferences regarding cookies by clicking on "Manage Individual preferences".',
              acceptAllBtn: "Accept necessary",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage Individual preferences",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept necessary",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Cookie usage",
                  description:
                    "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.",
                },
                {
                  title: "Strictly necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of website. Without these cookies, the website would not work properly.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Analytics cookies",
                  description:
                    "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
          "pt-BR": {
            consentModal: {
              title: "Este site usa cookies",
              description:
                'Usamos cookies para melhorar sua experiência de usuário, para coletar estatísticas para analisar como o site é usado e para publicidade personalizada nas mídias sociais e outros sites que você visita. Ao clicar em "Aceitar todos", você está dando seu consentimento para o uso de cookies pela Equinor Sell To e terceiros neste site. Você também pode escolher suas preferências em relação aos cookies, clicando em "Gerenciar preferências individuais".',
              acceptAllBtn: "Aceitar todos",
              acceptNecessaryBtn: "Aceitar necessários",
              showPreferencesBtn: "Gerenciar preferências individuais",
            },
            preferencesModal: {
              title: "Gerenciar preferências de cookies",
              acceptAllBtn: "Aceitar todos",
              acceptNecessaryBtn: "Aceitar necessários",
              savePreferencesBtn: "Aceitar a seleção atual",
              closeIconLabel: "Fechar modal",
              sections: [
                {
                  title: "Uso de cookies",
                  description:
                    "Usamos cookies para garantir as funcionalidades básicas do site e para aprimorar sua experiência online. Você pode escolher cada categoria para aceitar/rejeitar sempre que quiser.",
                },
                {
                  title: "Cookies estritamente necessários",
                  description:
                    "Esses cookies são essenciais para o bom funcionamento do site. Sem esses cookies, o site não funcionaria corretamente.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Cookies analíticos",
                  description:
                    "Esses cookies coletam informações sobre como você usa o site, quais páginas você visitou e em quais links você clicou. Todos os dados são anonimizados e não podem ser usados para identificá-lo.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return null;
};

export default CookieConsent;
