import Currency from "../@types/services/Currency";
import api from "./api";

const getCurrencies = async (): Promise<Currency[]> => {
  const response = await api.get<Currency[]>(`/currencies`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export default getCurrencies;
