import api from "./api";

export const fetchHelps = async (page: number, type: string) => {
  const response = await api.get("/helps", {
    baseURL: "/vbuyer/api/v2",
    params: {
      page,
      type,
    },
  });

  return response.data;
};
