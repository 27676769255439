/* eslint-disable react/jsx-props-no-spreading */
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  OutlinedInput,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ServiceQuotation from "../../@types/services/ServiceQuotation";
import Task from "../../@types/services/Task";
import ListAttachmentServiceQuotation from "../../components/molecules/ListAttachmentServiceQuotation";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import ModalScopeOfWork from "../../components/molecules/ModalScopeOfWork";
import ProgressBar from "../../components/molecules/ProgressBar";
import ServiceTable from "../../components/molecules/ServiceTable";
// eslint-disable-next-line import/no-cycle
import ServiceNegotiation from "../../@types/services/ServiceNegotiation";
import AssignToService from "../../components/molecules/AssignToService";
import MaintenanceOrder from "../../components/molecules/MaintenanceOrder";
import ServiceBillingInformation from "../../components/molecules/ServiceBillingInformation";
import ServiceNegotiationAudits from "../../components/molecules/ServiceNegotiationAudits";
import ServiceNegotiationChats from "../../components/molecules/ServiceNegotiationChats";
import ServiceNegotiationTable from "../../components/molecules/ServiceNegotiationTable";
import ServiceQuotationChat from "../../components/molecules/ServiceQuotationChat";
import Actions from "../../components/organisms/Actions";
import {
  fetchServiceNegotiations,
  ServiceNegotiationFilter,
} from "../../services/serviceNegotiations";
import { getServiceQuotation } from "../../services/serviceQuotations";
import NotFound from "../notFound";

const ShowServiceQuotation: FC = () => {
  const { serviceQuotationId } = useParams();
  const [serviceQuotation, setServiceQuotation] = useState<ServiceQuotation>();
  const [serviceNegotiations, setServiceNegotiations] =
    useState<ServiceNegotiation[]>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [value, setValue] = useState("0");
  const [finished, setFinished] = useState(false);

  const disableActions = async (sq: ServiceQuotation): Promise<void> => {
    if (!sq?.process_instance) {
      return;
    }

    const list = sq?.process_instance.tasks;
    list.forEach((x: Task): void => {
      if (
        x.status.name === "Waiting Approval" ||
        x.status.name === "Issuing Purchase Order" ||
        x.status.name === "Cancelled" ||
        x.status.name === "Finished"
      ) {
        setFinished(true);
      }
    });
  };

  const handleFetchServiceQuotation = useCallback(
    async (id: number): Promise<void> => {
      setLoading(() => true);
      await getServiceQuotation(id)
        .then((resource) => {
          setServiceQuotation(() => resource);
          disableActions(resource);
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [],
  );

  const handleFetchServiceNegotiations = useCallback(
    async (filters?: ServiceNegotiationFilter) => {
      setLoading(() => true);
      await fetchServiceNegotiations({
        filters,
      })
        .then((sq) => {
          if (sq.results) {
            setServiceNegotiations(sq.results);
          }
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!serviceQuotationId) {
      setNotFound(() => true);
      return;
    }

    handleFetchServiceQuotation(parseInt(serviceQuotationId, 10));
    handleFetchServiceNegotiations({
      service_quotation_id: parseInt(serviceQuotationId, 10),
    });
  }, [
    serviceQuotationId,
    handleFetchServiceNegotiations,
    handleFetchServiceQuotation,
  ]);

  if (notFound || !serviceQuotationId) {
    return <NotFound />;
  }

  const handleChange = (event: SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };

  if (loading) {
    return <LoadingCentral />;
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: "0px 20px",
          display: "flex",
        }}
      >
        <div>
          <h2>{i18next.t("serviceQuotation.title")}</h2>
          <h3 style={{ marginTop: "-10px" }}>
            #{serviceQuotation?.identifier}
          </h3>
        </div>
        {serviceQuotation?.process_instance && (
          <ProgressBar process={serviceQuotation?.process_instance} />
        )}
      </Box>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar color="gray" position="static">
            <Toolbar variant="dense">
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {i18next.t("details")}
              </Typography>
              <ButtonGroup
                size="small"
                aria-label="Small button group"
                sx={{ gap: "5px" }}
              >
                {serviceQuotation && (
                  <>
                    {serviceQuotation?.purchase_order_item?.purchase_order &&
                      serviceQuotation.purchase_order_item.purchase_order
                        .purchase_order_pdf_document?.attachment && (
                        <Button
                          variant="contained"
                          color="success"
                          href={`/vbuyer/purchase_orders/${serviceQuotation.purchase_order_item.purchase_order.id}/download.pdf`}
                          download={
                            serviceQuotation.purchase_order_item.purchase_order
                              .purchase_order_pdf_document.attachment.filename
                          }
                          target="_blank"
                          disableElevation
                        >
                          Download Purchase Order
                        </Button>
                      )}
                    <ModalScopeOfWork
                      serviceQuotation={serviceQuotation}
                      handleFetchServiceQuotation={handleFetchServiceQuotation}
                    />{" "}
                    {serviceQuotation?.scope_of_work && !finished ? (
                      <Actions
                        entityId={serviceQuotation.id}
                        options={["invite_supplier"]}
                      />
                    ) : (
                      <Button size="small" variant="contained" disabled>
                        {i18next.t("actions")}
                      </Button>
                    )}
                  </>
                )}
              </ButtonGroup>
            </Toolbar>
          </AppBar>
        </Box>
        <Card variant="outlined" square>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.prNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotation?.pr_item?.pr_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.itemNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotation?.pr_item?.item_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceQuotation.requisitionDate")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotation?.pr_item?.requisition_date
                    ? i18next.t("dateTime", {
                        val: Date.parse(
                          serviceQuotation.pr_item.requisition_date,
                        ),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            timeZone: "UTC",
                          },
                        },
                      })
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.shortDescription")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotation?.short_description || ""}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceQuotation.plant")}
                </Typography>
                <Typography variant="subtitle2">
                  {`${serviceQuotation?.pr_item?.plant?.plant_number} - ${serviceQuotation?.pr_item?.plant?.name}` ||
                    ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.purchaseGroup")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotation?.pr_item?.purchase_group.name || ""}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography variant="body2">
                  {i18next.t("serviceQuotation.assignedTo")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotation && (
                    <AssignToService
                      service_quotation={serviceQuotation}
                      handleFetchServiceQuotation={handleFetchServiceQuotation}
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={7}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.description")}
                </Typography>
                <OutlinedInput
                  id="description"
                  multiline
                  rows={5}
                  value={serviceQuotation?.long_text || ""}
                  sx={{
                    fontSize: "0.9em",
                    marginTop: "16px",
                    backgroundColor: "white", // Define a cor de fundo
                    "&.Mui-disabled": {
                      backgroundColor: "rgba(0, 0, 0, 0.12)", // Define a cor de fundo quando desabilitado
                      opacity: 1, // Garante que a opacidade seja 1
                      color: "black",
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                  readOnly
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <Container>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab
                label={i18next.t("serviceNegotiation.negotiations")}
                value="0"
              />
              <Tab label={i18next.t("serviceNegotiation.services")} value="1" />
              <Tab label={i18next.t("attachments")} value="2" />
              <Tab
                label={i18next.t("serviceNegotiation.internalChat")}
                value="3"
              />
              <Tab
                label={i18next.t(
                  "serviceQuotation.plantMaintenanceOrder.title",
                )}
                value="4"
              />
              <Tab
                label={i18next.t("serviceQuotation.moreInformation")}
                value="5"
              />
              <Tab label={i18next.t("serviceNegotiation.audits")} value="6" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceNegotiations ? (
                <>
                  <ServiceNegotiationTable
                    serviceNegotiations={serviceNegotiations}
                  />

                  <ServiceNegotiationChats
                    serviceNegotiations={serviceNegotiations}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    "& > *": {
                      m: 1,
                    },
                  }}
                >
                  <Chip label={<span>{i18next.t("noInvitedVendor")}</span>} />
                </Box>
              )}
            </Container>
          </TabPanel>
          <TabPanel value="1">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceQuotation && (
                <ServiceTable rowsService={serviceQuotation.service_lines} />
              )}
            </Container>
          </TabPanel>
          <TabPanel value="2">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceQuotation?.id && (
                <ListAttachmentServiceQuotation
                  serviceQuotationId={serviceQuotation?.id}
                />
              )}
            </Container>
          </TabPanel>
          <TabPanel value="3">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceQuotation?.chat && (
                <ServiceQuotationChat
                  serviceQuotationChat={serviceQuotation.chat}
                />
              )}
            </Container>
          </TabPanel>
          <TabPanel value="4">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceQuotation && (
                <MaintenanceOrder
                  purchaseRequisition={serviceQuotation.pr_item}
                />
              )}
            </Container>
          </TabPanel>
          <TabPanel value="5">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceQuotation && (
                <ServiceBillingInformation
                  purchaseRequisition={serviceQuotation.pr_item}
                />
              )}
            </Container>
          </TabPanel>
          <TabPanel value="6">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceQuotation && (
                <ServiceNegotiationAudits serviceQuotation={serviceQuotation} />
              )}
            </Container>
          </TabPanel>
        </TabContext>
      </Container>
    </Box>
  );
};

export default ShowServiceQuotation;
