import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Form, Formik } from "formik";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { revert_reject } from "../../../services/serviceNegotiations";

interface FormValues {
  comment: string;
}

interface RevertRejectionProps {
  serviceNegotiationId: number;
  revertOpen: boolean;
  setRevertOpen: Dispatch<SetStateAction<boolean>>;
}

const RevertRejection: FC<RevertRejectionProps> = ({
  serviceNegotiationId,
  revertOpen,
  setRevertOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const initialValues: FormValues = {
    comment: "",
  };

  return (
    <Dialog
      key={serviceNegotiationId}
      open={revertOpen}
      onClose={() => {
        setRevertOpen(false);
      }}
      fullWidth
      maxWidth="lg"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          revert_reject(serviceNegotiationId)
            .then(() => {
              enqueueSnackbar(i18next.t(""));
            })
            .catch((error) => {
              if (error.response) {
                enqueueSnackbar(i18next.t(""));
              } else {
                enqueueSnackbar(i18next.t(""));
              }
            })
            .finally(() => {
              setLoading(() => false);
            });
          setRevertOpen(false);
          window.location.href = window.location.href;
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              {i18next.t("serviceNegotiation.reject.confirmRevertReject")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {i18next.t("serviceNegotiation.reject.questionRevertReject")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRevertOpen(false)} color="primary">
                {i18next.t("no")}
              </Button>
              <LoadingButton
                variant="contained"
                color="success"
                loading={loading}
                loadingPosition="center"
                type="submit"
              >
                {i18next.t("yes")}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default RevertRejection;
