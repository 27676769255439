import { FC, ReactElement, Suspense } from "react";
import { CircularIndeterminate } from "../components/molecules";
import useAuth from "../hooks/useAuth";
import AuthProvider from "./AuthProvider";
// import { ProfileProvider, useProfile } from './Profile';

const AppProvider: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Suspense fallback={<CircularIndeterminate />}>
      <AuthProvider>
        <Suspense fallback={<CircularIndeterminate />}>
          {/* <ProfileProvider> */}
          <Suspense fallback={<CircularIndeterminate />}>{children}</Suspense>
          {/* </ProfileProvider> */}
        </Suspense>
      </AuthProvider>
    </Suspense>
  );
};

// export { useAuth, useProfile };
export { useAuth };

export default AppProvider;
