/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import i18next from "i18next";
import { FC, useCallback, useEffect, useState } from "react";
import Audits from "../../../@types/services/Audits";
import Proposal from "../../../@types/services/Proposal";
import ServiceQuotation from "../../../@types/services/ServiceQuotation";
import Vendor from "../../../@types/services/Vendor";
import {
  ServiceNegotiationFilter,
  fetchServiceNegotiations,
} from "../../../services/serviceNegotiations";
import {
  activityProps,
  fetchActivityLogsByQuotation,
} from "../../../services/serviceQuotations";

interface ServiceNegotiationAuditsProps {
  serviceQuotation: ServiceQuotation;
}

const renderActivity = (activity: string) => {
  try {
    const json = JSON.parse(activity);
    return (
      <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {Object.entries(json).map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong> {String(value)}
          </div>
        ))}
      </div>
    );
  } catch (e) {
    return <span>{activity}</span>;
  }
};

const ServiceNegotiationAudits: FC<ServiceNegotiationAuditsProps> = ({
  serviceQuotation,
}) => {
  const [serviceNegotiations, setServiceNegotiations] = useState<unknown[]>();
  const [activityLogs, setActivityLogs] = useState<activityProps[]>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const handleFetchServiceQuotationAudits = useCallback(
    async (filters?: ServiceNegotiationFilter) => {
      setLoading(() => true);
      await fetchServiceNegotiations({
        filters,
      })
        .then((sq) => {
          setServiceNegotiations(() => sq.results || []);
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [],
  );

  const handleFetchActivityLogs = useCallback(async (id: number) => {
    setLoading(() => true);
    await fetchActivityLogsByQuotation(id)
      .then((resource) => {
        setActivityLogs(resource);
      })
      .catch(() => {
        setNotFound(() => true);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  useEffect(() => {
    if (!serviceQuotation) {
      setNotFound(() => true);
      return;
    }

    handleFetchServiceQuotationAudits({
      service_quotation_id: serviceQuotation?.id,
    });
    handleFetchActivityLogs(serviceQuotation?.id);
  }, [
    handleFetchServiceQuotationAudits,
    handleFetchActivityLogs,
    serviceQuotation,
  ]);

  type ServiceNegotiationProps = {
    id: number;
    vendor: Vendor;
    proposal: Proposal;
    created_at: Date;
    audits: Audits;
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Updated by</TableCell>
              <TableCell align="center">action</TableCell>
              <TableCell align="center">activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityLogs && activityLogs?.length > 0
              ? activityLogs.map((activityLog: activityProps) => (
                  <TableRow key={activityLog.id}>
                    <TableCell>
                      {activityLog.act_tstamp &&
                        i18next.t("dateTime", {
                          val: Date.parse(activityLog.act_tstamp),
                          interpolation: {
                            escapeValue: false,
                          },
                          formatParams: {
                            val: {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                              // hour12: false,
                            },
                          },
                        })}
                    </TableCell>
                    <TableCell align="center">
                      {activityLog.updated_by}
                    </TableCell>
                    <TableCell align="center">
                      {activityLog.act_action}
                    </TableCell>
                    <TableCell align="center">
                      {renderActivity(activityLog.activity)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServiceNegotiationAudits;
