import { Paper, Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
}

const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <Paper sx={{ padding: "24px", width: "100%" }}>
      <Typography variant="h5" component="div">
        {title}
      </Typography>
    </Paper>
  );
};

export default PageTitle;
