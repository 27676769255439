import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { reject } from "../../../services/serviceNegotiations";

interface FormValues {
  reason: string;
  comment: string;
}

interface RejectServiceNegotiationProps {
  serviceNegotiationId: number;
  rejectOpen: boolean;
  setRejectOpen: Dispatch<SetStateAction<boolean>>;
}

const RejectServiceNegotiation: FC<RejectServiceNegotiationProps> = ({
  serviceNegotiationId,
  rejectOpen,
  setRejectOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const initialValues: FormValues = {
    reason: "",
    comment: "",
  };

  return (
    <Dialog
      key={serviceNegotiationId}
      open={rejectOpen}
      onClose={() => {
        setRejectOpen(false);
      }}
      fullWidth
      maxWidth="lg"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          reject(serviceNegotiationId, values)
            .then(() => {
              enqueueSnackbar(i18next.t(""));
            })
            .catch((error) => {
              if (error.response) {
                enqueueSnackbar(i18next.t(""));
              } else {
                enqueueSnackbar(i18next.t(""));
              }
            })
            .finally(() => {
              setLoading(() => false);
            });
          setRejectOpen(false);
          window.location.href = window.location.href;
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              {i18next.t("serviceNegotiation.reject.confirmReject")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {i18next.t("serviceNegotiation.reject.questionReject")}
              </DialogContentText>
              <FormControl fullWidth margin="normal">
                <InputLabel>
                  {i18next.t("serviceNegotiation.reject.reason")}
                </InputLabel>
                <Select
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  label={i18next.t("serviceNegotiation.reject.reason")}
                  required
                >
                  {Object.keys(
                    i18next.t("serviceRejectionReasons", {
                      returnObjects: true,
                    }),
                  ).map((key) => (
                    <MenuItem key={key} value={key}>
                      {i18next.t(`serviceRejectionReasons.${key}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                multiline
                rows={3}
                name="comment"
                label={i18next.t("serviceNegotiation.reject.comment")}
                value={values.comment}
                onChange={handleChange}
                fullWidth
                inputProps={{ maxLength: 5000 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRejectOpen(false)} color="primary">
                {i18next.t("cancel")}
              </Button>
              <LoadingButton
                variant="contained"
                color="error"
                loading={loading}
                loadingPosition="center"
                type="submit"
              >
                {i18next.t("reject")}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default RejectServiceNegotiation;
