import { CreatePurchaseOrderByQuotation } from "../@types/services";
import api from "./api";

interface ListCreatePurchaseOrderByQuotationsParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  filterCompleted?: boolean;
}

interface CreatePurchaseOrderByQuotationResponse {
  total_items: number;
  results: CreatePurchaseOrderByQuotation[] | null;
}

export const listCreatePurchaseOrderByQuotations = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  filterCompleted,
}: ListCreatePurchaseOrderByQuotationsParams): Promise<CreatePurchaseOrderByQuotationResponse> => {
  const response = await api.get<CreatePurchaseOrderByQuotationResponse>(
    "/create_purchase_order_by_quotations",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        order,
        completed: filterCompleted,
      },
    },
  );

  return response.data;
};

export const getCreatePurchaseOrderByQuotation = async (
  id: string,
): Promise<CreatePurchaseOrderByQuotation> => {
  const response = await api.get<CreatePurchaseOrderByQuotation>(
    `/create_purchase_order_by_quotations/${id}`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const createCreatePurchaseOrderByQuotation = async (
  quotationIds: number[],
): Promise<CreatePurchaseOrderByQuotation> => {
  const response = await api.post<CreatePurchaseOrderByQuotation>(
    `/create_purchase_order_by_quotations`,
    {
      quotation_ids: quotationIds,
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};
