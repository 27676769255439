import PurchaseOrderType from "../@types/services/PurchaseOrderType";
import api from "./api";

const getPurchaseOrderType = async (): Promise<PurchaseOrderType[]> => {
  const response = await api.get<PurchaseOrderType[]>(`/purchase_order_types`, {
    baseURL: "/vbuyer/api/v2",
  });

  return response.data;
};

export default getPurchaseOrderType;
