import { FC, ReactElement, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { CircularProgress } from "@mui/material";

const Edit: FC = (): ReactElement => {
  const { user } = useAuth();

  useEffect(() => {
    location.reload();
  })

  return (
    <CircularProgress size="lg" />
  );
};

export default Edit;
