import UpdateSapDeliveryTerm from "../@types/services/UpdateSapDeliveryTerm";
import api from "./api";

const API_BASE_URL = "/vbuyer/api/v2/delivery_terms";

export const massUpdateByApiDeliveryTerms = async (
  items: UpdateSapDeliveryTerm[],
): Promise<void> => {
  await api.post<UpdateSapDeliveryTerm>(
    `/mass_update_by_api`,
    {
      items,
    },
    {
      baseURL: API_BASE_URL,
    },
  );

  return;
};
