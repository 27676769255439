import axios from "axios";
import config from "../config";

export interface ApiParams {
  page?: number;
  // pagination?: string;
  itemsPerPage?: number;
}

const api = axios.create({
  baseURL: config.api.url,
  xsrfCookieName: "SELLTO-CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-Token",
  withCredentials: true,
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
});

export default api;
