import {
  Card,
  CardContent,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC } from "react";
import PurchaseRequisition from "../../../@types/services/PurchaseRequisition";

interface ServiceBillingInformationProps {
  purchaseRequisition: PurchaseRequisition;
}

const ServiceBillingInformation: FC<ServiceBillingInformationProps> = ({
  purchaseRequisition,
}) => {
  return (
    <Card variant="outlined" square>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">
              {i18next.t("serviceQuotation.billingInformation")}
            </Typography>
            <OutlinedInput
              id="billingInformation"
              multiline
              rows={5}
              value={
                purchaseRequisition.plant.display_billing_information || ""
              }
              sx={{
                fontSize: "0.9em",
                marginTop: "16px",
                backgroundColor: "white", // Define a cor de fundo
                "&.Mui-disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)", // Define a cor de fundo quando desabilitado
                  opacity: 1, // Garante que a opacidade seja 1
                  color: "black",
                  WebkitTextFillColor: "black",
                },
              }}
              fullWidth
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" component="div">
              POB Information
            </Typography>
            <OutlinedInput
              id="billingInformation"
              multiline
              rows={5}
              value={purchaseRequisition.plant.display_pob_information || ""}
              sx={{
                fontSize: "0.9em",
                marginTop: "16px",
                backgroundColor: "white", // Define a cor de fundo
                "&.Mui-disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)", // Define a cor de fundo quando desabilitado
                  opacity: 1, // Garante que a opacidade seja 1
                  color: "black",
                  WebkitTextFillColor: "black",
                },
              }}
              fullWidth
              readOnly
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServiceBillingInformation;
