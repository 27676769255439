import { Material } from "../@types/services";
import api from "./api";

const API_BASE_URL = "/vbuyer/api/v2/materials";

export type MaterialsFilter = {
  id?: number;
  material_number?: string | string[];
  free_text?: boolean;
};

interface MaterialsFetch {
  filters?: MaterialsFilter;
}

interface MaterialsResponse {
  total_items: number;
  results: Material[] | null;
}

export const fetchMaterials = async ({
  filters,
}: MaterialsFetch): Promise<MaterialsResponse> => {
  const response = await api.get<MaterialsResponse>("", {
    baseURL: API_BASE_URL,
    params: {
      ...filters,
    },
  });
  return response.data;
};
