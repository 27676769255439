import ServiceQuotationApproval from "../@types/services/ServiceQuotationApproval";
import api from "./api";

export const getServiceQuotationApproval = async (
  id: number,
): Promise<ServiceQuotationApproval> => {
  const response = await api.get<ServiceQuotationApproval>(
    `/service_quotation_approvals/${id}`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const updateServiceQuotationApproval = async (
  serviceQuotationApprovalId: number,
  serviceQuotationApproval: ServiceQuotationApproval,
): Promise<ServiceQuotationApproval> => {
  const response = await api.put<ServiceQuotationApproval>(
    `/service_quotation_approvals/${serviceQuotationApprovalId}`,
    {
      service_quotation_approval: {
        justification: serviceQuotationApproval.justification,
        decision: serviceQuotationApproval.decision,
      },
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};
