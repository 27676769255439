interface Config {
  navigationType: "hash" | "history";
  useSampleData?: boolean;
  api: {
    useMocks?: boolean;
    url: string;
    urlGql: string;
  };
  auth: {
    signIn: string;
    signOut: string;
    validateToken: string;
  };
}

const config: Config = {
  navigationType: "history",
  useSampleData: false,
  api: {
    useMocks: false,
    url: process.env.REACT_APP_API_URL || "/api/v1", // TODO: Update when use React pure
    urlGql: process.env.REACT_APP_API_GQL || "/api/v1/graphql", // TODO: Update when use React pure
  },
  auth: {
    signIn: process.env.REACT_APP_AUTH_SIGN_IN_URL || "/auth/sign_in", // TODO: Update when use React pure
    signOut: process.env.REACT_APP_AUTH_SIGN_OUT_URL || "/auth/sign_out", // TODO: Update when use React pure
    validateToken:
      process.env.REACT_APP_AUTH_VALIDATE_TOKEN_URL || "/auth/validate_token", // TODO: Update when use React pure
  },
};

export default config;
