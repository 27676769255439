import { mdiContentSaveOutline } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AssignConditionType from "../../../@types/services/AssignConditionType";
import {
  fetchAssignConditions,
  updateAssignConditions,
} from "../../../services/assignConditions";

type UserAssignConditionProps = {
  userId: string;
  userAssignConditionsList: AssignConditionType[];
  setUserAssignConditionsList: Dispatch<SetStateAction<AssignConditionType[]>>;
};
type FormValues = {
  assignConditions: AssignConditionType[];
};

const UserAssignConditions: FC<UserAssignConditionProps> = ({
  userId,
  userAssignConditionsList,
  setUserAssignConditionsList,
}): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {
      assignConditions: userAssignConditionsList,
    },
  });

  const currentValues = watch("assignConditions");

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const [assignConditions, setAssignConditions] =
    useState<AssignConditionType[]>();

  const handleFetchAssignConditions = useCallback(async () => {
    const response = await fetchAssignConditions();
    setAssignConditions(response.results);
  }, []);

  useEffect(() => {
    handleFetchAssignConditions();
  }, []);

  useEffect(() => {
    if (userAssignConditionsList?.length === currentValues?.length) {
      const sortedUserAssignConditionsList = userAssignConditionsList
        .map((item) => item.id)
        .sort();
      const sortedCurrentValues = currentValues.map((item) => item.id).sort();
      if (
        JSON.stringify(sortedUserAssignConditionsList) ===
        JSON.stringify(sortedCurrentValues)
      ) {
        setDisableSubmitButton(true);
      } else {
        setDisableSubmitButton(false);
      }
    } else {
      setDisableSubmitButton(false);
    }
  }, [userAssignConditionsList, currentValues]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setDisableSubmitButton(true);
    updateAssignConditions(userId, data.assignConditions).then((response) => {
      setUserAssignConditionsList(response.assign_conditions);
      enqueueSnackbar("Condições de Atribuição salvas com sucesso");
    });
  };

  return (
    <Paper
      sx={{
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flexShrink: 0,
        flexGrow: 1,
      }}
    >
      {assignConditions && userAssignConditionsList ? (
        <>
          <Typography variant="h6" component="div">
            {"Condições de atribuição"}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {assignConditions && (
              <Controller
                name="assignConditions"
                control={control}
                render={({ field: { onChange, value, ...fieldProps } }) => (
                  <Autocomplete
                    {...fieldProps}
                    multiple
                    disablePortal
                    options={assignConditions}
                    defaultValue={userAssignConditionsList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue || []);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        key={params.inputProps.id}
                        label="Selecione as Condições de Atribuição"
                      />
                    )}
                  />
                )}
              />
            )}

            <Paper
              elevation={0}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: "center",
                paddingTop: "16px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ maxWidth: "350px" }}
                disabled={disableSubmitButton}
                startIcon={<Icon path={mdiContentSaveOutline} size={1} />}
              >
                {"Salvar"}
              </Button>
            </Paper>
          </form>
        </>
      ) : (
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Paper>
      )}
    </Paper>
  );
};

export default UserAssignConditions;
