/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@mui/material";
import { FC } from "react";

interface ActionProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  sxClass?: any;
  type?: "submit";
}

const FilterAction: FC<ActionProps> = ({
  text,
  onClick,
  sxClass,
  type,
  disabled,
}) => {
  return (
    <Button
      type={type}
      sx={sxClass}
      onClick={onClick}
      disabled={disabled}
      style={{ marginTop: "8px", justifyContent: "center" }}
    >
      {text}
    </Button>
  );
};

export default FilterAction;
