/* eslint-disable react/jsx-props-no-spreading */
import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import ServiceNegotiation from "../../../@types/services/ServiceNegotiation";
import {
  DocumentProps,
  fetchDocuments,
} from "../../../services/serviceQuotations";
import BasicBar from "../BasicBar";

const label = { inputProps: { "aria-label": "Switch demo" } };

interface ListAttachmentServiceNegotiationProps {
  serviceNegotiation: ServiceNegotiation;
}

const ListAttachmentServiceNegotiation: FC<
  ListAttachmentServiceNegotiationProps
> = ({ serviceNegotiation }) => {
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async (
    url: string,
    filename: string,
  ): Promise<void> => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    fetchDocuments(serviceNegotiation.service_quotation.id)
      .then((resource) => {
        setDocuments(resource);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          enqueueSnackbar(e.response.data.message);
        } else {
          enqueueSnackbar("Não foi possível carregar dados!");
        }
      });
  }, [enqueueSnackbar, serviceNegotiation.service_quotation.id]);

  return (
    <>
      <BasicBar title={i18next.t("attachments")} />

      <Card sx={{ margin: 2, minWidth: 275 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 170 }}>
                {i18next.t("user")}
              </TableCell>
              <TableCell align="center" style={{ minWidth: 170 }}>
                {i18next.t("filename")}
              </TableCell>
              <TableCell align="center" style={{ minWidth: 170 }}>
                #
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length > 0 &&
              documents.map((document) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={document.url}
                >
                  <TableCell align="center">
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                      {`${document.mail_user} - ${i18next.t("dateTime", {
                        val: Date.parse(String(document.created_at)),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            // hour12: false,
                          },
                        },
                      })}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                      {document.filename}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleDownload(document.url, document.filename)
                      }
                    >
                      Download <Icon path={mdiDownload} size={0.8} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default ListAttachmentServiceNegotiation;
