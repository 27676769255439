import { ThemeOptions } from "@mui/material";

const light = (): ThemeOptions =>
  ({
    palette: {
      mode: "light",
      background: {
        default: "#f8f8f8",
      },
      white: {
        main: "#fff",
        contrastText: "#000",
      },
      gray: {
        main: "#D9D9D9",
        contrastText: "#000",
      },
      energyRed: {
        main: "#ff1243",
        contrastText: "#fff",
      },
      mistBlue: {
        main: "#dff5ff",
        contrastText: "#243746",
      },
      lichenGreen: {
        main: "#e6faec",
        contrastText: "#243746",
      },
      spruceWood: {
        main: "#ffe7d6",
        contrastText: "#243746",
      },
      slateBlue: {
        main: "#243746",
        contrastText: "#fff",
      },
      massGreen: {
        main: "#007079",
        contrastText: "#fff",
      },
      heritageRed: {
        main: "#7d0023",
        contrastText: "#fff",
      },
    },
  }) as ThemeOptions;

export default light;
