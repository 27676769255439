import {
  Stack,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { ReactElement } from "react";
import ProcessInstance from "../../../@types/services/ProcessInstance";
import Task from "../../../@types/services/Task";

const BorderStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.mode === "light" ? "#57AED1" : "#308fe8",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.mode === "light" ? "#57AED1" : "#308fe8",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    height: 12,
    borderRadius: 5,
    boxShadow: "0px 3px 2px 0px rgba(0,0,0,0.25) inset",
  },
}));

const BorderStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "white",
  display: "flex",
  zIndex: 1,
  alignItems: "center",
  border: "3px solid white",
  borderRadius: "50px",
  width: "50px",
  height: "50px",
  background: "#DDDDDD",
  boxShadow: "4px 4px 6px 0px rgba(0,0,0,0.25) inset",
  justifyContent: "center",
  ...(ownerState.active && {
    background: "#8BC53F",
  }),
  ...(ownerState.completed && {
    background: "#57AED1",
  }),
}));

const BorderStepIcon = (props: StepIconProps): ReactElement => {
  const { active, completed, className, icon } = props;

  return (
    <BorderStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icon}
    </BorderStepIconRoot>
  );
};

interface ProgressBarProps {
  maxSize?: number;
  process: ProcessInstance;
}

const ProgressBar = ({
  maxSize = 5,
  process,
}: ProgressBarProps): ReactElement => {
  const lastTasks: Task[] = process.tasks
    .sort((a, b) => a.id - b.id)
    .slice(-maxSize);

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "20px",
        padding: "20px",
      }}
    >
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={lastTasks.length - 1}
          connector={<BorderStepConnector />}
        >
          {[...Array(maxSize)].map((_, index) => (
            <Step key={lastTasks[index]?.id}>
              <StepLabel StepIconComponent={BorderStepIcon}>
                <h4
                  style={{
                    color:
                      lastTasks.length - 1 === index ? "#8BC53F" : "#57AED1",
                  }}
                >
                  {lastTasks[index] &&
                    (lastTasks[index].status?.title || "No Status")}
                </h4>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
};

export default ProgressBar;
