/* eslint-disable react/jsx-props-no-spreading */
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import LoadingCentral from "../../components/molecules/LoadingCentral";
// eslint-disable-next-line import/no-cycle
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Proposal from "../../@types/services/Proposal";
import ProposalLine from "../../@types/services/ProposalLine";
import ArchivedProposals from "../../components/molecules/ArchivedProposals";
import DownloadProposals from "../../components/molecules/DownloadProposals";
import ProposalAudit from "../../components/molecules/ProposalAudit";
import { fetchProposal } from "../../services/proposals";

const ShowProposal: FC = () => {
  const { proposalId } = useParams();
  const [proposal, setProposal] = useState<Proposal>();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("0");

  const handleFetchProposal = async (id: string) => {
    setLoading(true);
    await fetchProposal(id)
      .then((response) => {
        setProposal(response);
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  useEffect(() => {
    if (proposalId) {
      handleFetchProposal(proposalId);
    }
  }, [proposalId]);

  const handleChange = (event: SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };

  if (loading || !proposal) {
    return <LoadingCentral />;
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: "0px 20px",
          display: "flex",
        }}
      >
        <div>
          <h2>{proposal.vendor?.fullvendorname}</h2>
          <h3 style={{ marginTop: "-10px" }}>{proposal?.service_quotation?.identifier} - {proposal.short_description}</h3>
        </div>
        {/* {serviceQuotation?.process_instance && (
          <ProgressBar process={proposal?.service_negotiation?.process_instance} />
        )} */}
      </Box>
      <Container
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <AppBar color="gray" position="static">
            <Toolbar variant="dense">
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {i18next.t("details")}
              </Typography>
              <ButtonGroup
                size="small"
                aria-label="Small button group"
                sx={{ gap: "5px" }}
              >
                <DownloadProposals proposal={proposal} />
              </ButtonGroup>
            </Toolbar>
          </AppBar>
        </Box>
        <Card variant="outlined" square>
          <CardContent sx={{ p: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                mb: 1,
              }}
            >
              <Chip
                label={`${i18next.t("newProposal.deliveryDate")}: ${i18next.t(
                  "dateTime",
                  {
                    val: Date.parse(proposal.delivery_date),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        timeZone: "UTC",
                        // hour: "false",
                        // minute: "false",
                        // second: "false",
                        // hour12: false,
                      },
                    },
                  },
                )}`}
              />
              <Chip
                label={`${i18next.t("newProposal.createdAt")}: ${i18next.t(
                  "dateTime",
                  {
                    val: Date.parse(proposal.created_at),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        // hour: "false",
                        // minute: "false",
                        // second: "false",
                        // hour12: false,
                      },
                    },
                  },
                )}`}
              />
              <Chip
                label={`${i18next.t("newProposal.updatedAt")}: ${i18next.t(
                  "dateTime",
                  {
                    val: Date.parse(String(proposal.updated_at)),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        // hour: "false",
                        // minute: "false",
                        // second: "false",
                        // hour12: false,
                      },
                    },
                  },
                )}`}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {" "}
                      {i18next.t("serviceNegotiation.shortDescription")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {i18next.t("newProposal.quantity")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {i18next.t("newProposal.measureUnit")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {i18next.t("serviceNegotiation.grossPrice")}{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposal.proposal_lines
                    .sort((a, b) => (a.position || 0) - (b.position || 0))
                    .map((proposalLine: ProposalLine) => (
                      <TableRow
                        key={proposalLine.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>{proposalLine.short_text}</TableCell>
                        <TableCell align="right">
                          {proposalLine.quantity}
                        </TableCell>
                        <TableCell align="right">
                          {proposalLine.measure_unit?.name}
                        </TableCell>
                        <TableCell align="right">
                          {proposal.currency.unit} {proposalLine.gross_price}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow
                    key={proposal.id}
                    sx={{
                      justifyContent: "space-between",
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="right">
                      {proposal.currency.unit} {proposal.price}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>

      <Container>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab label={i18next.t("proposal.archivedProposals")} value="0" />
              <Tab label={i18next.t("serviceNegotiation.audits")} value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Container
              sx={{ width: "100%", minHeight: "400px", marginTop: "5px" }}
            >
              <ArchivedProposals proposal={proposal} />
            </Container>
          </TabPanel>
          <TabPanel value="1">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              <ProposalAudit proposal={proposal} />
            </Container>
          </TabPanel>
        </TabContext>
      </Container>
    </Box>
  );
};

export default ShowProposal;
