import FilterAction from "./FilterAction";
import FilterActions from "./FilterActions";
import FilterMultiSelectField from "./FilterMultiSelectField";
import FilterRoot from "./FilterRoot";
import FilterSelectField from "./FilterSelectField";
import FilterTextField from "./FilterTextField";

export const Filter = {
  Root: FilterRoot,
  Action: FilterAction,
  Actions: FilterActions,
  TextField: FilterTextField,
  SelectField: FilterSelectField,
  MultiSelectField: FilterMultiSelectField,
};
