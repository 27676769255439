import AssignConditionType from "../@types/services/AssignConditionType";
import api from "./api";

const API_BASE_URL = "/vbuyer/api/v2/assign_conditions";

export const fetchAssignConditions = async () => {
  const response = await api.get("", {
    baseURL: API_BASE_URL,
  });
  return response.data;
};

export const updateAssignConditions = async (
  userId: string,
  assignConditions: AssignConditionType[],
) => {
  const response = await api.put(
    `/${userId}`,
    {
      assign_condition_ids: assignConditions.map(
        (assignCondition) => assignCondition.id,
      ),
    },
    {
      baseURL: API_BASE_URL,
    },
  );
  return response.data;
};

export const fetchManageUserAssignConditions = async (userId: string) => {
  const response = await api.get(`/${userId}/manage_user_assign_conditions`, {
    baseURL: API_BASE_URL,
  });
  return response.data;
};

export const fetchAssignConditionsUsersList = async () => {
  const response = await api.get(`/user_list`, {
    baseURL: API_BASE_URL,
  });
  return response.data;
};
