/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { mdiFilter } from "@mdi/js";
import { Icon } from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Unstable_Grid2,
} from "@mui/material";
import i18next from "i18next";
import { FC, ReactNode, useState } from "react";

interface FilterRootProps {
  children: ReactNode;
}

const FilterRoot: FC<FilterRootProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = (): void => {
    setOpen(!open);
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleToggle}
      sx={{ marginY: 2, display: "flex", flexDirection: "column" }}
    >
      <AccordionSummary onClick={handleToggle} sx={{ justifyContent: "left" }}>
        <span>
          <Icon size={0.8} style={{ paddingTop: "4px" }} path={mdiFilter} />{" "}
          {i18next.t("filter")}
        </span>
      </AccordionSummary>
      <AccordionDetails sx={{ marginX: 2 }}>
        <Unstable_Grid2
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {children}
        </Unstable_Grid2>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterRoot;
