import {
  TextFieldProps as MuiTextFieldProps,
  TextField,
  Unstable_Grid2,
} from "@mui/material";
import { FC } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

type TextFieldProps = MuiTextFieldProps & {
  name: FieldPath<FieldValues>;
  control: Control<FieldValues>;
};

const FilterTextField: FC<TextFieldProps> = ({ name, control, ...props }) => {
  return (
    <Unstable_Grid2 xs={6}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            {...props}
            value={field.value === undefined ? "" : field.value}
          />
        )}
      />
    </Unstable_Grid2>
  );
};

export default FilterTextField;
