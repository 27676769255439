import { Box, Pagination } from "@mui/material";
import {
  FC,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Vendor } from "../../@types/Vendor";
import { Filter } from "../../components/molecules/Filter";
import ListVendors from "../../components/molecules/ListVendors";
import { VendorsFilter, fetchVendors } from "../../services/vendors";

const Vendors: FC = (): ReactElement => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const { control, handleSubmit, reset } = useForm();

  const handleFetchVendors = useCallback(
    async (page: number, filters?: VendorsFilter) => {
      await fetchVendors({
        page,
        itemsPerPage: rowsPerPage,
        filters,
      }).then((vendor) => {
        setVendors(() => vendor.results || []);
        setTotalRows(() => vendor.total_items || 0);
      });
    },
    [rowsPerPage],
  );

  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ): void => {
    setCurrentPage(() => value);
  };

  const handleFilterSubmit: SubmitHandler<VendorsFilter> = (data): void => {
    setCurrentPage(() => 1);
    handleFetchVendors(currentPage, data);
  };

  const handleFilterClear = (): void => {
    reset();
    setCurrentPage(() => 1);
    handleFetchVendors(currentPage);
  };

  useEffect(() => {
    handleFetchVendors(currentPage);
  }, [currentPage, handleFetchVendors]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1,
        justifyContent: "center",
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit(handleFilterSubmit)}>
          <Filter.Root>
            <Filter.TextField
              control={control}
              type="text"
              label="Name"
              name="name"
            />
            <Filter.TextField
              control={control}
              type="text"
              label="E-mail"
              name="email"
            />
            <Filter.TextField
              control={control}
              type="number"
              label="Código SAP"
              name="sap_code"
            />
            <Filter.TextField
              control={control}
              type="number"
              label="CNPJ"
              name="tax_number"
            />
            <Filter.Actions>
              <Filter.Action text="Aplicar" type="submit" />
              <Filter.Action
                text="Limpar"
                onClick={handleFilterClear}
                sxClass={{
                  color: "red",
                  "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                }}
              />
            </Filter.Actions>
          </Filter.Root>
        </form>
        <ListVendors vendors={vendors} />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalRows / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Vendors;
