import MeasureUnit from "../@types/services/MeasureUnit";
import api from "./api";

export interface MeasureUnitsFetch {
  names?: string[];
}

const getMeasureUnits = async ({
  names,
}: MeasureUnitsFetch): Promise<MeasureUnit[]> => {
  const response = await api.get<MeasureUnit[]>(`/measure_units`, {
    baseURL: "/vbuyer/api/v2",
    params: {
      names,
    },
  });
  return response.data;
};

export default getMeasureUnits;
