import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ptBR from "./locales/pt-BR.json";

const userLanguage = document.documentElement.lang || navigator.language;

i18next.use(initReactI18next).init({
  // lng: 'pt-BR', // if you're using a language detector, do not define the lng option
  lng: userLanguage,
  resources: {
    en: {
      translation: en,
    },
    "pt-BR": {
      translation: ptBR,
    },
  },
});

export default i18next;
