import { mdiAccountDetails } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import i18next from "i18next";
import { FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import ServiceNegotiation from "../../../@types/services/ServiceNegotiation";

interface ListServiceNegotiationProps {
  serviceNegotiations: ServiceNegotiation[];
  loading: boolean;
}

const ListServiceNegotiations: FC<ListServiceNegotiationProps> = ({
  serviceNegotiations,
  loading,
}): ReactElement => {
  return (
    <Card
      sx={{
        p: 2,
        borderRadius: "10px",
        border: "5px",
        width: "100%",
        height: "100%",
      }}
    >
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: `30%` }} align="left">
                {i18next.t("serviceQuotation.identifier")}
              </TableCell>
              <TableCell sx={{ width: `20%` }} align="left">
                {i18next.t("serviceNegotiation.status")}
              </TableCell>
              <TableCell sx={{ width: `30%` }} align="left">
                {i18next.t("serviceNegotiation.shortDescription")}
              </TableCell>
              <TableCell sx={{ width: `10%` }} align="left">
                {i18next.t("serviceNegotiation.prNumber")}
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="left">
                {i18next.t("createdAt")}
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="center">
                #
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow key={1}>
                {Array.from(Array(6), (index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            )}
            {!loading && serviceNegotiations?.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  {i18next.t("noInviteServiceNegotiation")}
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              serviceNegotiations?.length > 0 &&
              serviceNegotiations.map(
                (serviceNegotiation: ServiceNegotiation) => (
                  <TableRow
                    key={serviceNegotiation.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ width: `30%` }}
                    >{`${serviceNegotiation.service_quotation.identifier}`}</TableCell>
                    <TableCell
                      sx={{ width: `20%` }}
                    >{`${serviceNegotiation.status.title}`}</TableCell>
                    <TableCell
                      sx={{ width: `30%` }}
                    >{`${serviceNegotiation.service_quotation.short_description}`}</TableCell>
                    <TableCell
                      sx={{ width: `10%` }}
                    >{`${serviceNegotiation.service_quotation.pr_item.pr_number}`}</TableCell>
                    <TableCell
                      sx={{ width: `5%` }}
                    >{`${serviceNegotiation.created_at}`}</TableCell>
                    <TableCell sx={{ width: `5%` }} align="center">
                      <Tooltip
                        title={i18next.t(
                          "serviceNegotiation.tooltip.moreInfos",
                        )}
                      >
                        <Button
                          // style={{ backgroundColor: "blue", color: "black" }}
                          component={Link}
                          variant="outlined"
                          to={`/vbuyer/service_negotiations/${serviceNegotiation.id}`}
                        >
                          <Icon size={1} path={mdiAccountDetails} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ListServiceNegotiations;
