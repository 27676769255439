import { mdiDownload } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Button, ButtonGroup } from "@mui/material";
import { FC } from "react";
import Proposal from "../../../@types/services/Proposal";
import { getProposalDocument } from "../../../services/proposals";

type DownloadProposalProps = {
  proposal: Proposal;
};

const DownloadProposals: FC<DownloadProposalProps> = ({ proposal }) => {
  const handleDownload = async (id: string, type: string): Promise<void> => {
    if (!id) {
      return;
    }

    const response = await getProposalDocument(id, type);

    const link = document.createElement("a");
    link.href = response.url;
    link.setAttribute("download", response.filename);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(response.url);
  };

  return (
    <ButtonGroup size="small" aria-label="Small button group">
      <Button
        onClick={() => handleDownload(proposal.id, "technical")}
        disabled={!proposal}
      >
        Technical <Icon path={mdiDownload} size={0.6} />
      </Button>
      <Button
        onClick={() => handleDownload(proposal.id, "commercial")}
        disabled={!proposal}
      >
        Commercial <Icon path={mdiDownload} size={0.6} />
      </Button>
    </ButtonGroup>
  );
};

export default DownloadProposals;
