import { Box } from "@mui/material";
import { FC, ReactElement, useCallback, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import SymbolRedLoading from "../../images/equinor-symbol-red-loading.gif";
import ProtectedRoute from "../components/templates/ProtectedRoute";
import useAuth from "../hooks/useAuth";
import AssignConditions from "../pages/AssignConditions";
import ManageUserAssignConditions from "../pages/AssignConditions/manage_user_assign_conditions";
import CreatePurchaseOrderByQuotations from "../pages/CreatePurchaseOrderByQuotations";
import ShowCreatePurchaseOrderByQuotation from "../pages/CreatePurchaseOrderByQuotations/show";
import CreatePurchaseOrderByServiceQuotations from "../pages/CreatePurchaseOrderByServiceQuotations";
import ShowCreatePurchaseOrderByServiceQuotation from "../pages/CreatePurchaseOrderByServiceQuotations/show";
import DeliveryTerms from "../pages/DeliveryTerms";
import DuimpRequest from "../pages/DuimpRequest";
import EditProposal from "../pages/EditProposal";
import { Helps } from "../pages/Helps/index";
import MaterialRequestForms from "../pages/MaterialRequestForms";
import ShowMaterialRequestForm from "../pages/MaterialRequestForms/show";
import NewProposal from "../pages/NewProposal";
import ShowProposal from "../pages/Proposal/show";
import IssuePurchaseOrder from "../pages/Quotations/issuePurchaseOrder";
import ServiceNegotiations from "../pages/ServiceNegotiations";
import ShowServiceNegotiation from "../pages/ServiceNegotiations/show";
import ShowServiceQuotationApproval from "../pages/ServiceQuotationApprovals/show";
import ServiceQuotations from "../pages/ServiceQuotations";
import IssueServicePurchaseOrder from "../pages/ServiceQuotations/issueServicePurchaseOrder";
import ShowServiceQuotation from "../pages/ServiceQuotations/show";
import Vendors from "../pages/Vendors";
import Home from "../pages/home";
import Login from "../pages/login";
import NotFound from "../pages/notFound";
import Private from "../pages/private";
import UsersEdit from "../pages/users/edit";

const RedirectRailsHome: FC = (): ReactElement => {
  const redirectToRoot = useCallback(async () => {
    await new Promise((r) => {
      setTimeout(r, 1500);
    });
    window.location.href = window.origin;
  }, []);

  useEffect(() => {
    redirectToRoot();
  }, [redirectToRoot]);

  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "rgba(248, 249, 250)",
        zIndex: 2000,
      }}
    >
      <img
        src={SymbolRedLoading}
        alt="Loading..."
        style={{ height: "100px", margin: "1.5rem" }}
      />

      <h1 style={{ fontSize: "36px", color: "rgb(108, 117, 125)" }}>
        Sell To Equinor | Brazil
      </h1>

      <h3 style={{ color: "rgb(108, 117, 125)" }}>Loading, please wait</h3>
    </Box>
  );
};

const AppRoutes: FC = (): ReactElement => {
  const { user } = useAuth();

  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<RedirectRailsHome />} />
        <Route path="/redirect/rails/home" element={<RedirectRailsHome />} />
        <Route path="/react/demo" element={<Home />} />
        <Route path="/users/sign_in" element={<Login />} />
        <Route path="/react/sign_out" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<RedirectRailsHome />} />
      <Route path="/redirect/rails/home" element={<RedirectRailsHome />} />
      <Route element={<ProtectedRoute isAllowed={!!user} />}>
        <Route path="/react/demo" element={<Home />} />
        {/* <Route path="/react/chat" element={<HomeChat />} /> */}
        <Route path="/users/edit" element={<UsersEdit />} />
        <Route path="/react/private" element={<Private />} />

        <Route path="/vbuyer/assign_conditions">
          <Route index element={<AssignConditions />} />
          <Route
            path=":userId/manage_user_assign_conditions"
            element={<ManageUserAssignConditions />}
          />
        </Route>

        <Route path="/vbuyer/new_delivery_terms">
          <Route index element={<DeliveryTerms />} />
        </Route>

        <Route path="/vbuyer/service_quotations">
          <Route index element={<ServiceQuotations />} />
          <Route
            path=":serviceQuotationId"
            element={<ShowServiceQuotation />}
          />
        </Route>
        <Route path="/vbuyer/service_negotiations">
          <Route index element={<ServiceNegotiations />} />
          <Route
            path=":serviceNegotiationId"
            element={<ShowServiceNegotiation />}
          />
        </Route>
        <Route path="/vbuyer/proposals">
          <Route path=":proposalId" element={<ShowProposal />} />
        </Route>
        <Route path="/vbuyer/service_quotation_approvals">
          <Route
            path=":serviceQuotationApprovalId"
            element={<ShowServiceQuotationApproval />}
          />
        </Route>
        <Route
          path="/vbuyer/helps/index"
          element={<Helps type_help="general" />}
        />
        <Route
          path="/vbuyer/helps/invoice_and_payment_procedures"
          element={<Helps type_help="invoice and payment procedures" />}
        />
        <Route path="/vbuyer/material_request_forms">
          <Route index element={<MaterialRequestForms />} />
          <Route
            path=":materialRequestFormId"
            element={<ShowMaterialRequestForm />}
          />
        </Route>

        <Route path="/vbuyer/quotations">
          {/* <Route index element={<Quotations />} /> */}
          {/* <Route path=":quotationId" element={<ShowQuotation />} /> */}
          <Route path="issue_purchase_order" element={<IssuePurchaseOrder />} />
        </Route>
        <Route path="/vbuyer/service_quotations">
          {/* <Route index element={<Quotations />} /> */}
          {/* <Route path=":quotationId" element={<ShowQuotation />} /> */}
          <Route
            path="issue_service_purchase_order"
            element={<IssueServicePurchaseOrder />}
          />
        </Route>
        <Route path="/vbuyer/create_purchase_order_by_quotations">
          <Route index element={<CreatePurchaseOrderByQuotations />} />
          <Route
            path=":createPurchaseOrderByQuotationId"
            element={<ShowCreatePurchaseOrderByQuotation />}
          />
        </Route>
        <Route path="/vbuyer/create_purchase_order_by_service_quotations">
          <Route index element={<CreatePurchaseOrderByServiceQuotations />} />
          <Route
            path=":createPurchaseOrderByServiceQuotationId"
            element={<ShowCreatePurchaseOrderByServiceQuotation />}
          />
        </Route>
        <Route
          path="/vbuyer/service_negotiations/:serviceNegotiationId/proposals/new"
          element={<NewProposal />}
        />
        <Route
          path="/vbuyer/service_negotiations/:serviceNegotiationId/proposals/:proposalId/edit"
          element={<EditProposal />}
        />
        <Route path="/vsupplierregistration/vendors/">
          <Route index element={<Vendors />} />
          {/* <Route path=":vendorId" element={<></>} /> */}
        </Route>

        <Route path="/vbuyer/duimp_requests/new" element={<DuimpRequest />} />
      </Route>

      {/* <Route
        element={
          <ProtectedRoute
            isAllowed={!!user && user.roles.includes("mm_requester")}
          />
        }
      >
        <Route
          path="/vbuyer/mm_requests/create_new_requests"
          element={
            <ProtectedRoute
              redirectPath="/home"
              isAllowed={
                !!user && user.permissions.includes("create_new_requests")
              }
            >
              <AxiosExample />
            </ProtectedRoute>
          }
        />
      </Route> */}

      {/* <Route path="/users/sign_in" element={<Navigate to="/" />} /> */}
      <Route path="/users/sign_in" element={<Navigate to="/" />} />
      <Route path="/react/sign_out" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
