import { Box, Card, CircularProgress } from "@mui/material";
import i18next from "i18next";
import { FC, ReactElement } from "react";

const LoadingCentral: FC = (): ReactElement => {
  return (
    <Card
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "20",
      }}
    >
      <Box sx={{ paddingX: 3, paddingTop: 2 }}>
        <CircularProgress size={100} />
        <h3>{i18next.t("loading")}</h3>
      </Box>
    </Card>
  );
};

export default LoadingCentral;
