import { FC, ReactElement } from "react";
import useAuth from "../hooks/useAuth";

const Private: FC = (): ReactElement => {
  const { user } = useAuth();

  return (
    <div>
      <br />
      <h2>Private Page</h2>
      Olá {user?.name}, tudo bem?
    </div>
  );
};

export default Private;
