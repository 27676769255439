import { Box, Paper, Typography } from "@mui/material";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AssignConditionType from "../../@types/services/AssignConditionType";
import UserAssignCondition from "../../@types/services/UserAssignCondition";
import UserAssignConditions from "../../components/molecules/UserAssignConditions";
import UserWeeklyGoals from "../../components/molecules/UserWeeklyGoals";
import { fetchManageUserAssignConditions } from "../../services/assignConditions";

const ManageUserAssignCondition: FC = (): ReactElement => {
  const { userId } = useParams();
  const [manageUserAssignConditions, setManageUserAssignConditions] =
    useState<UserAssignCondition>();
  const [userAssignConditionsList, setUserAssignConditionsList] =
    useState<AssignConditionType[]>();

  const handleFetchManageUser = useCallback(async (id: string) => {
    const response = await fetchManageUserAssignConditions(id);
    setManageUserAssignConditions(response);
    setUserAssignConditionsList(response.assign_conditions);
  }, []);

  useEffect(() => {
    if (userId) {
      handleFetchManageUser(userId);
    }
  }, [userId]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Paper sx={{ padding: "24px", width: "100%" }}>
        <Typography variant="h5" component="div">
          {manageUserAssignConditions?.first_name}{" "}
          {manageUserAssignConditions?.last_name}
        </Typography>
      </Paper>

      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {userId && userAssignConditionsList && (
          <UserAssignConditions
            userId={userId}
            userAssignConditionsList={userAssignConditionsList}
            setUserAssignConditionsList={setUserAssignConditionsList}
          />
        )}

        {userId && <UserWeeklyGoals userId={userId} />}
      </Box>
    </Box>
  );
};

export default ManageUserAssignCondition;
