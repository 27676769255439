import { mdiEye, mdiInformation, mdiScriptTextPlay } from "@mdi/js";
import Icon from "@mdi/react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link as RouterLink } from "react-router-dom";
import ServiceQuotation from "../../@types/services/ServiceQuotation";
import { createCreatePurchaseOrderByServiceQuotation } from "../../services/createPurchaseOrderByServiceQuotations";
import { listServiceQuotationsIssuePurchaseOrder } from "../../services/serviceQuotations";

type DataTabProps = {
  valid: boolean;
  selected: number[];
  selectedCallback: Dispatch<SetStateAction<number[]>>;
};

const DataTab: FC<DataTabProps> = ({
  valid,
  selected,
  selectedCallback,
}): ReactElement => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [serviceQuotations, setServiceQuotations] = useState<
    ServiceQuotation[]
  >([]);
  const [loading, setLoading] = useState(true);
  const maximumSelectedItems = 100;

  const handleFetchServiceQuotations = useCallback(
    async (page: number) => {
      setLoading(() => true);
      await listServiceQuotationsIssuePurchaseOrder({
        page,
        itemsPerPage: rowsPerPage,
        order: "desc",
        valid,
      })
        .then((serviceQuotation) => {
          setServiceQuotations(() => serviceQuotation.results || []);
          setTotalRows(() => serviceQuotation.total_items || 0);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [rowsPerPage, valid],
  );

  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ): void => {
    setCurrentPage(() => value + 1);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(() => parseInt(event.target.value, 10));
    setCurrentPage(() => 1);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      let newSelected: number[] = [];
      newSelected = newSelected.concat(selected);

      for (const serviceQuotation of serviceQuotations) {
        if (newSelected.length >= maximumSelectedItems) {
          break;
        } else {
          if (!newSelected.includes(serviceQuotation.id)) {
            newSelected.push(serviceQuotation.id);
          }
        }
      }

      selectedCallback(() => newSelected);
      return;
    }

    selectedCallback(() =>
      selected.filter(
        (el) =>
          !serviceQuotations
            .map((serviceQuotation) => serviceQuotation.id)
            .includes(el),
      ),
    );
  };

  const handleSelectClick = (
    event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number,
  ): void => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      if (selected.length >= maximumSelectedItems) {
        newSelected = selected;
      } else {
        newSelected = newSelected.concat(selected, id);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    selectedCallback(() => newSelected);
  };

  const isTableRowSelected = (id: number): boolean => {
    return selected.indexOf(id) !== -1;
  };

  useEffect(() => {
    handleFetchServiceQuotations(currentPage);
  }, [currentPage, handleFetchServiceQuotations]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={
                  selected.length > 0 &&
                  selected.length !== serviceQuotations.length
                }
                checked={
                  serviceQuotations.length > 0 &&
                  selected.length === serviceQuotations.length
                }
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell align="left">
              {i18next.t("serviceQuotation.identifier")}
            </TableCell>
            <TableCell align="left">
              {i18next.t("serviceQuotation.requisitionDate")}
            </TableCell>
            <TableCell align="left">
              {i18next.t("serviceQuotation.service")}
            </TableCell>
            <TableCell align="left">
              {i18next.t("serviceQuotation.vendor")}
            </TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow key={1}>
              {Array.from(Array(6), (index) => (
                <TableCell key={index}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          )}
          {!loading &&
            serviceQuotations?.length > 0 &&
            serviceQuotations.map((serviceQuotation) => (
              <TableRow
                key={serviceQuotation.id}
                hover
                onClick={(
                  event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
                ) => handleSelectClick(event, serviceQuotation.id)}
                role="checkbox"
                aria-checked={isTableRowSelected(serviceQuotation.id)}
                tabIndex={-1}
                selected={isTableRowSelected(serviceQuotation.id)}
                sx={{ cursor: "pointer" }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isTableRowSelected(serviceQuotation.id)}
                  />
                </TableCell>
                <TableCell>{serviceQuotation.identifier}</TableCell>
                <TableCell>
                  <span>
                    {i18next.t("dateTime", {
                      val: Date.parse(
                        serviceQuotation.pr_item.requisition_date,
                      ),
                      interpolation: { escapeValue: false },
                      formatParams: {
                        val: {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          timeZone: "UTC",
                        },
                      },
                    })}
                  </span>
                </TableCell>
                <TableCell>
                  {serviceQuotation.pr_item.material?.matnumber}
                </TableCell>
                <TableCell>
                  {serviceQuotation.vendor_winner?.fullvendorname}
                </TableCell>

                <TableCell align="center">
                  <Tooltip title={i18next.t("view")}>
                    <IconButton
                      component={Link}
                      // to={`/vbuyer/quotations/${quotation.id}`}
                      href={`/vbuyer/service_quotations/${serviceQuotation.id}`}
                      target="_blank"
                    >
                      <Icon path={mdiEye} size={0.8} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 1,
                  }}
                >
                  <Tooltip
                    title={i18next.t(
                      "issueServicePurchaseOrder.maximumSelectedItems",
                      { count: maximumSelectedItems },
                    )}
                  >
                    <Icon path={mdiInformation} size={1} />
                  </Tooltip>
                </Box>
                <Typography>
                  {i18next.t("rowSelected", { count: selected.length })}
                </Typography>
              </Box>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const IssuingPurchaseOrder: FC = (): ReactElement => {
  const [selected, setSelected] = useState<number[]>([]);
  const [loadingGeneratePurchaseOrder, setLoadingGeneratePurchaseOrder] =
    useState(false);
  const [tabOpen, setTabOpen] = useState("valid");

  const handleGeneratePurchaseOrder = (): void => {
    setLoadingGeneratePurchaseOrder(() => true);

    createCreatePurchaseOrderByServiceQuotation(selected)
      .then((createPurchaseOrderByServiceQuotation) => {
        window.location.href = `/vbuyer/api/v2/create_purchase_order_by_service_quotations/${createPurchaseOrderByServiceQuotation.id}/generate_purchase_order`;
      })
      .finally(() => {
        setLoadingGeneratePurchaseOrder(() => false);
      });
  };

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setTabOpen(() => newValue);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>{i18next.t("issueServicePurchaseOrder.title")}</h1>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            component={RouterLink}
            to="/vbuyer/create_purchase_order_by_service_quotations"
          >
            <Icon path={mdiScriptTextPlay} size={1} />
            <span>
              {i18next.t("issueServicePurchaseOrder.trackExecutions")}
            </span>
          </Button>
        </Box>
      </Box>

      {loadingGeneratePurchaseOrder ? (
        <Button variant="outlined" color="info">
          <CircularProgress
            color="success"
            size={16}
            sx={{ marginRight: "5px" }}
          />
          <span>
            {i18next.t("issueServicePurchaseOrder.generatingPurchaseOrder")}
          </span>
        </Button>
      ) : (
        <Button
          variant="outlined"
          type="button"
          onClick={handleGeneratePurchaseOrder}
          disabled={selected.length <= 0}
        >
          {i18next.t("issueServicePurchaseOrder.generatePurchaseOrder")}
        </Button>
      )}

      <TabContext value={tabOpen}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabOpen} onChange={handleTabChange}>
            <Tab
              label={i18next.t("issueServicePurchaseOrder.quotationsValid")}
              value="valid"
            />
            <Tab
              label={i18next.t("issueServicePurchaseOrder.quotationsInvalid")}
              value="invalid"
            />
          </Tabs>
        </Box>

        <TabPanel value="valid">
          <DataTab valid selected={selected} selectedCallback={setSelected} />
        </TabPanel>
        <TabPanel value="invalid">
          <DataTab
            valid={false}
            selected={selected}
            selectedCallback={setSelected}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default IssuingPurchaseOrder;
