import ServiceNegotiation from "../@types/services/ServiceNegotiation";
import api from "./api";

export type ServiceNegotiationFilter = {
  identifier?: number;
  status_name?: string[];
  service_quotation_id?: number;
  vendor_id?: number;
};

interface ServiceNegotiationsFetch {
  page?: number;
  itemsPerPage?: number;
  filters?: ServiceNegotiationFilter;
}

interface ServiceNegotiationsResponse {
  total_items: number;
  results: ServiceNegotiation[] | null;
}

// eslint-disable-next-line import/prefer-default-export
export const fetchServiceNegotiations = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: ServiceNegotiationsFetch): Promise<ServiceNegotiationsResponse> => {
  const response = await api.get<ServiceNegotiationsResponse>(
    "/service_negotiations",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        ...filters,
      },
    },
  );

  return response.data;
};

export const getServiceNegotiation = async (
  id: number,
): Promise<ServiceNegotiation> => {
  const response = await api.get<ServiceNegotiation>(
    `/service_negotiations/${id}`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const defineWinner = async (
  negotiationId: number,
  formData: {
    justification: string;
    rfq_info: number | "";
    award_documents?: File[];
  },
): Promise<ServiceNegotiation> => {
  const response = await api.postForm<ServiceNegotiation>(
    `/service_negotiations/${negotiationId}/define_winner`,
    formData,
    {
      baseURL: `/vbuyer/api/v2`,
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
};

export const reject = async (
  negotiationId: number,
  formData: { reason: string; comment: string },
): Promise<ServiceNegotiation> => {
  const response = await api.postForm<ServiceNegotiation>(
    `service_negotiations/${negotiationId}/reject`,
    { comment: formData.comment, reason: formData.reason },
    {
      baseURL: `/vbuyer/api/v2`,
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
};

export const revert_reject = async (
  negotiationId: number,
): Promise<ServiceNegotiation> => {
  const response = await api.post<ServiceNegotiation>(
    `service_negotiations/${negotiationId}/revert_reject`,
    {},
    {
      baseURL: `/vbuyer/api/v2`,
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
};

export const addVendorDocument = async (
  id: number,
  attach: unknown,
): Promise<ServiceNegotiation> => {
  const response = await api.postForm<ServiceNegotiation>(
    `/service_negotiations/${id}/add_vendor_document`,
    attach,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

// export const fetchVendorDocuments = async (
//   id: number,
// ): Promise<DocumentProps[]> => {
//   const response = await api.get<DocumentProps[]>(
//     "service_negotiations/get_vendor_documents",
//     {
//       baseURL: "/vbuyer/api/v2",
//       params: {
//         id,
//       },
//     },
//   );

//   return response.data;
// };
