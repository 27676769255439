import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import i18next from "i18next";
import { FC } from "react";
import ServiceLine from "../../../@types/services/ServiceLine";

interface ServiceTableProps {
  rowsService: ServiceLine[];
}

const ServiceTable: FC<ServiceTableProps> = ({ rowsService }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {i18next.t("serviceNegotiation.serviceLineNumber")}
            </TableCell>
            <TableCell>
              {i18next.t("serviceNegotiation.serviceNumber")}
            </TableCell>
            <TableCell>
              {i18next.t("serviceNegotiation.shortDescription")}
            </TableCell>
            <TableCell align="right">
              {i18next.t("newProposal.quantity")}
            </TableCell>
            <TableCell align="right">
              {i18next.t("newProposal.measureUnit")}
            </TableCell>
            <TableCell align="right">
              {i18next.t("serviceNegotiation.grossPrice")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsService &&
            rowsService
              .sort(
                (a, b) => a.service_line_number_ext - b.service_line_number_ext,
              )
              .map((serviceLine: ServiceLine) => (
                <TableRow
                  key={serviceLine.service_line_number_ext}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{serviceLine.service_line_number_ext}</TableCell>
                  <TableCell>{serviceLine.service_number}</TableCell>
                  <TableCell>{serviceLine.short_text}</TableCell>
                  <TableCell align="right">{serviceLine.quantity}</TableCell>
                  <TableCell align="right">
                    {serviceLine.measure_unit?.name}
                  </TableCell>
                  <TableCell align="right">{serviceLine.gross_price}</TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiceTable;
