import { mdiContentSaveOutline, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { User } from "../../../@types/services";
import DuimpRequest from "../../../@types/services/DuimpRequest";
import Material from "../../../@types/services/Material";
import { createDuimpRequest } from "../../../services/duimpRequests";
import { fetchMaterials } from "../../../services/materials";
import {
  fetchQuantityOfMaterialsOptions,
  fetchRequesterFieldOptions,
} from "../../../services/mm_requests";
import { fetchUsers } from "../../../services/users";

type FormValues = {
  watchers: User[];
  requester_field: string;
  quantity_of_materials: string;
  matnumber: Material[];
  subject: string;
  description: string;
  file_attachments: File[];
};

type quantityOfMaterialOptionsType = string[] | null;

const DuimpRequestForm = () => {
  const [quantityOfMaterialOptions, setQuantityOfMaterialOptions] =
    useState<quantityOfMaterialOptionsType>(null);
  const [requesterFieldOptions, setRequesterFieldOptions] = useState<string[]>(
    [],
  );
  const [materials, setMaterials] = useState<Material[]>([]);
  const [watchersLoading, setWatchersLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [watchers, setWatchers] = useState<User[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [fileCount, setFileCount] = useState(0);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      // Valores padrão para o form, sempre passar.
      watchers: [],
      requester_field: "",
      quantity_of_materials: "",
      matnumber: [],
      subject: "",
      description: "",
      file_attachments: [],
    },
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(async (data) => {
    const formattedData: DuimpRequest = {
      reason: "DUIMP",
      watchers:
        data.watchers && data.watchers.length > 0
          ? data.watchers.map((watcher) => watcher.id)
          : [],
      requester_field: data.requester_field,
      quantity_of_materials: data.quantity_of_materials,
      matnumber:
        data.matnumber && data.matnumber.length > 0
          ? data.matnumber.map((material) => material.matnumber)
          : [],
      subject: data.subject,
      description: data.description,
    };
    setLoadingSubmit(true);
    await createDuimpRequest(formattedData, data.file_attachments)
      .then((response) => {
        enqueueSnackbar(i18next.t("duimpRequests.successMessage"), {
          variant: "success",
        });
        window.location.href = `/vbuyer/mm_requests?flash_message=${i18next.t("duimpRequests.flashMessage")}${response.mm_request.id}`;
      })
      .catch((error) => {
        setLoadingSubmit(false);
        enqueueSnackbar(i18next.t("duimpRequests.errorMessage"), {
          variant: "error",
        });
      });
  }, []);

  const handleFetchMaterials = useCallback(async (filters?: any) => {
    if (filters && filters?.material_number?.length >= 3) {
      setMaterialsLoading(true);
      await fetchMaterials({
        filters,
      })
        .then((response) => {
          if (response.results) {
            const selectedMaterials = getValues("matnumber");
            if (selectedMaterials) {
              const combinedMaterials = [
                ...response.results.filter(
                  (item: { id: number }) =>
                    !selectedMaterials.some(
                      (selected) => selected.id === item.id,
                    ),
                ),
                ...selectedMaterials,
              ];
              setMaterials(combinedMaterials);
            } else {
              setMaterials(response.results);
            }
          } else {
            setMaterials(getValues("matnumber"));
          }
        })
        .catch((error) => {
          enqueueSnackbar(i18next.t("duimpRequests.importDataErrorMessage"), {
            variant: "error",
          });
        });
      setMaterialsLoading(false);
    }
  }, []);

  const handleFetchMmUsers = async (filters?: any) => {
    if (filters && filters?.name_or_login?.length >= 2) {
      setWatchersLoading(true);
      await fetchUsers({
        filters,
      })
        .then((response) => {
          if (response.results) {
            const selectedWatchers = getValues("watchers");
            if (selectedWatchers) {
              const combinedWatchers = [
                ...response.results.filter(
                  (item: { id: number }) =>
                    !selectedWatchers.some(
                      (selected) => selected.id === item.id,
                    ),
                ),
                ...selectedWatchers,
              ];
              setWatchers(combinedWatchers);
            } else {
              setWatchers(response.results);
            }
          } else {
            setWatchers(getValues("watchers"));
          }
        })
        .catch((error) => {
          enqueueSnackbar(i18next.t("duimpRequests.importDataErrorMessage"), {
            variant: "error",
          });
        });
      setWatchersLoading(false);
    }
  };

  useEffect(() => {
    fetchQuantityOfMaterialsOptions().then((data) => {
      setQuantityOfMaterialOptions(data);
    });
    fetchRequesterFieldOptions().then((data) => {
      setRequesterFieldOptions(data);
    });
  }, []);

  return (
    <Paper
      sx={{
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flexShrink: 0,
        flexGrow: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Controller
            name="watchers"
            control={control}
            render={({ field }) => (
              <Autocomplete
                loading={watchersLoading}
                disablePortal
                multiple
                noOptionsText={i18next.t("duimpRequests.noOptionsText")}
                options={watchers}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={field.value || []}
                getOptionLabel={(option) => `${option?.full_name_with_login}`}
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                }}
                sx={{ width: "100%" }}
                onInputChange={(event, value) => {
                  handleFetchMmUsers({
                    name_or_login: value,
                    material_management_user: true,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.inputProps.id}
                    label={i18next.t("duimpRequests.watchers")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {watchersLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          />

          {requesterFieldOptions && (
            <Controller
              name="requester_field"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={requesterFieldOptions}
                  value={field.value || null}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18next.t("duimpRequests.requesterField")}
                    />
                  )}
                />
              )}
            />
          )}

          {quantityOfMaterialOptions && (
            <Controller
              name="quantity_of_materials"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={quantityOfMaterialOptions}
                  value={field.value || null}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18next.t("duimpRequests.quantityOfMaterials")}
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            name="matnumber"
            rules={{ required: i18next.t("duimpRequests.requiredMessage") }}
            control={control}
            render={({ field }) => (
              <Autocomplete
                loading={materialsLoading}
                disablePortal
                multiple
                noOptionsText={i18next.t("duimpRequests.noOptionsText")}
                options={materials}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.matnumber}
                value={field.value || []}
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                }}
                sx={{ width: "100%" }}
                onInputChange={(event, value) => {
                  handleFetchMaterials({
                    material_number: value,
                    free_text: false,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.inputProps.id}
                    label={i18next.t("duimpRequests.material")}
                    helperText={
                      errors.matnumber
                        ? errors.matnumber.message
                        : i18next.t("duimpRequests.materialHelper")
                    }
                    error={!!errors.matnumber}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {materialsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                )}
              />
            )}
          />
          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={i18next.t("duimpRequests.subject")}
                helperText={i18next.t("duimpRequests.subjectHelper")}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={6}
                label={i18next.t("duimpRequests.description")}
                helperText={i18next.t("duimpRequests.descriptionHelper")}
              />
            )}
          />

          <Divider />

          <Controller
            name="file_attachments"
            control={control}
            render={({ field }) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <input
                  style={{ display: "none" }}
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={(e) => {
                    const files = e.target.files || [];
                    field.onChange(files);
                    setFileCount(files.length);
                  }}
                />
                <label htmlFor="file-upload">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<Icon path={mdiUpload} size={1} />}
                  >
                    {i18next.t("duimpRequests.uploadButtonText")}
                  </Button>
                </label>
                {fileCount > 0 ? (
                  <span>
                    {fileCount} {i18next.t("duimpRequests.attachmentsText")}
                  </span>
                ) : null}
              </Box>
            )}
          />

          <Button
            variant="contained"
            type="submit"
            color="success"
            disabled={loadingSubmit}
            startIcon={
              loadingSubmit ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <Icon path={mdiContentSaveOutline} size={1} />
              )
            }
          >
            {i18next.t("duimpRequests.saveButtonText")}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default DuimpRequestForm;
