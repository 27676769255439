import { MaterialRequestForm } from "../@types/services";
import api from "./api";

interface ListMaterialRequestFormsParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  filterHasPurchaseOrder?: boolean;
}

interface MaterialRequestFormResponse {
  total_items: number;
  results: MaterialRequestForm[] | null;
}

export const listMaterialRequestForms = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  filterHasPurchaseOrder,
}: ListMaterialRequestFormsParams): Promise<MaterialRequestFormResponse> => {
  const response = await api.get<MaterialRequestFormResponse>(
    "/material_request_forms",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        order,
        has_purchase_order: filterHasPurchaseOrder,
      },
    },
  );

  return response.data;
};

export const getMaterialRequestForm = async (
  id: string,
): Promise<MaterialRequestForm> => {
  const response = await api.get<MaterialRequestForm>(
    `/material_request_forms/${id}`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const uploadMaterialRequestFormAttach = async (
  id: string,
  attach: unknown,
): Promise<MaterialRequestForm> => {
  const response = await api.postForm<MaterialRequestForm>(
    `/material_request_forms/${id}/mass_offer_upload`,
    attach,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};
