import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import ServiceQuotation from "../../../@types/services/ServiceQuotation";
import { addScopeOfWork } from "../../../services/serviceQuotations";

interface ScopeOfWorkProps {
  serviceQuotation: ServiceQuotation;
  handleFetchServiceQuotation: (id: number) => Promise<void>;
}

const ModalScopeOfWork: FC<ScopeOfWorkProps> = ({
  serviceQuotation,
  handleFetchServiceQuotation,
}) => {
  const [open, setOpen] = useState(false);
  const [attach, setAttach] = useState<File | undefined>();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (): Promise<void> => {
    setLoading(() => true);
    if (!attach) {
      return;
    }

    const formData = new FormData();
    formData.append("attachment", attach);

    addScopeOfWork(serviceQuotation.id, formData)
      .then(() => {
        enqueueSnackbar("Arquivo enviado com sucesso!");
        handleFetchServiceQuotation(serviceQuotation.id);
        setOpen(false);
      })
      .catch(() => {
        enqueueSnackbar("Não foi possível enviar o arquivo!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        disableElevation
      >
        {i18next.t("serviceNegotiation.scopeOfWork")}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{i18next.t("serviceNegotiation.scopeOfWork")}</DialogTitle>
        <DialogContent style={{ paddingTop: 20, maxHeight: "200px" }}>
          <Stack spacing={1} sx={{ width: 500 }}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                onChange={(e) => setAttach(() => e.target.files?.[0])}
                style={{
                  border: "1px solid black",
                  borderRadius: "2px",
                  padding: "5px",
                }}
              />
              <Button
                sx={{ width: 200 }}
                href={`/vbuyer/api/v2/service_quotations/${serviceQuotation.id}/get_scope_of_work`}
                download={serviceQuotation?.scope_of_work?.filename}
                disabled={!serviceQuotation?.scope_of_work}
                target="_blank"
              >
                File <Icon path={mdiDownload} size={0.8} />
              </Button>
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingPosition="center"
            onClick={() => onSubmit()}
            disabled={!attach}
          >
            Enviar arquivo
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalScopeOfWork;
