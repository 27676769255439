import {
  mdiAccountCircle,
  mdiBrightness4,
  mdiBrightness7,
  mdiLogout,
  mdiMenu,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  useTheme,
} from "@mui/material";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Link as LinkRoute } from "react-router-dom";
import devTag from "../../../../images/dev-tag.png";
import equinorLogoRedHorizontal from "../../../../images/equinor-logo-red-horizontal.svg";
import homologTag from "../../../../images/homolog-tag.png";
import useAuth from "../../../hooks/useAuth";
import useThemeMode from "../../../hooks/useThemeMode";
import { EnvTag, Logo } from "./styles";

const TopMenu: FC<{ setLeftMenuOpen: Dispatch<SetStateAction<boolean>> }> = ({
  setLeftMenuOpen,
}): ReactElement => {
  const { user, signOut } = useAuth();
  const { toggleThemeMode } = useThemeMode();
  const theme = useTheme();
  const [envTag] = useState(() => {
    if (window.environment === "development") {
      return devTag;
    }
    if (window.environment === "homolog") {
      return homologTag;
    }
    return null;
  });
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = false;

  const handleLeftMenu = useCallback(() => {
    setLeftMenuOpen((currentValue) => !currentValue);
  }, []);

  const handleLogout = useCallback(() => {
    return signOut();
  }, [signOut]);

  return (
    <AppBar
      position="sticky"
      color="white"
      sx={{ zIndex: (currentTheme) => currentTheme.zIndex.drawer + 1 }}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `"logo . . header"`,
        }}
      >
        <Toolbar
          sx={{
            height: (currentTheme) => currentTheme.mixins.toolbar.minHeight,
            gridArea: "logo",
          }}
        >
          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleLeftMenu}
              sx={{ mr: 2 }}
            >
              <Icon size={0.75} path={mdiMenu} />
            </IconButton>
          ) : (
            <>
              {envTag && <EnvTag src={envTag} />}
              <Logo src={equinorLogoRedHorizontal} />
            </>
          )}
        </Toolbar>
        <Box
          component="main"
          sx={{
            gridArea: "header",
            flexGrow: 1,
            justifyContent: "center",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 1,
            gridTemplateRows: "auto",
            gridTemplateAreas: `"mode my_account sign_out"`,
          }}
        >
          <IconButton
            onClick={toggleThemeMode}
            color="energyRed"
            sx={{ ml: 1, gridArea: "mode" }}
          >
            {theme.palette.mode === "dark" ? (
              <Icon size={0.75} path={mdiBrightness7} />
            ) : (
              <Icon size={0.75} path={mdiBrightness4} />
            )}
          </IconButton>
          <Button
            color="energyRed"
            variant="text"
            size="small"
            sx={{
              width: "100%",
              gridArea: "my_account",
              textTransform: "capitalize",
            }}
            component={LinkRoute}
            to="/users/edit"
          >
            <Icon size={0.75} path={mdiAccountCircle} /> Minha Conta
          </Button>
          {user && (
            <Button
              color="energyRed"
              variant="text"
              size="small"
              sx={{
                width: "100%",
                gridArea: "sign_out",
                textTransform: "capitalize",
              }}
              onClick={handleLogout}
            >
              <Icon size={0.75} path={mdiLogout} /> Sair
            </Button>
          )}
        </Box>
      </Box>
    </AppBar>
  );
};

export default TopMenu;
