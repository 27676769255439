import { mdiClipboardCheck, mdiClipboardClock, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { blue, green } from "@mui/material/colors";
import i18next from "i18next";
import {
  ChangeEvent,
  FC,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { MaterialRequestForm } from "../../@types/services";
import { listMaterialRequestForms } from "../../services/materialRequestForms";

type DataTabProps = {
  finished: boolean;
};

const DataTab: FC<DataTabProps> = ({ finished }): ReactElement => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [materialRequestForms, setMaterialRequestForms] = useState<
    MaterialRequestForm[]
  >([]);
  const [loading, setLoading] = useState(true);

  const handleFetchMaterialRequestForms = useCallback(
    async (page: number) => {
      setLoading(() => true);
      await listMaterialRequestForms({
        page,
        itemsPerPage: rowsPerPage,
        order: "desc",
        filterHasPurchaseOrder: finished,
      })
        .then((materialRequestForm) => {
          setMaterialRequestForms(() => materialRequestForm.results || []);
          setTotalRows(() => materialRequestForm.total_items || 0);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [rowsPerPage],
  );

  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ): void => {
    setCurrentPage(() => value + 1);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(() => parseInt(event.target.value, 10));
    setCurrentPage(() => 1);
  };

  useEffect(() => {
    handleFetchMaterialRequestForms(currentPage);
  }, [currentPage, handleFetchMaterialRequestForms]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Purchase Requisition</TableCell>
            <TableCell align="left">
              {i18next.t("materialRequestForm.requisitionDate")}
            </TableCell>
            <TableCell align="left">Main Work Order</TableCell>
            <TableCell align="left">
              {i18next.t("materialRequestForm.vendor")}
            </TableCell>
            <TableCell align="left">Purchase Order</TableCell>
            <TableCell align="center">
              {i18next.t("materialRequestForm.filled")}
            </TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow key={1}>
              {Array.from(Array(7), (index) => (
                <TableCell key={index}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          )}
          {!loading &&
            materialRequestForms?.length > 0 &&
            materialRequestForms.map((materialRequestForm) => (
              <TableRow key={materialRequestForm.id}>
                <TableCell>
                  {materialRequestForm.purchase_requisition_number}
                </TableCell>
                <TableCell>
                  <span>
                    {materialRequestForm.material_request_form_items?.length >
                      0 &&
                      i18next.t("dateTime", {
                        val: Date.parse(
                          materialRequestForm.material_request_form_items[0]
                            .purchase_requisition.requisition_date,
                        ),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            timeZone: "UTC",
                            // hour: "numeric",
                            // minute: "numeric",
                            // second: "numeric",
                            // hour12: false,
                          },
                        },
                      })}
                  </span>
                </TableCell>
                <TableCell>
                  {materialRequestForm.material_request_form_items?.length >
                    0 &&
                    materialRequestForm.material_request_form_items[0]
                      .purchase_requisition.account_assignment_category ===
                      "F" &&
                    materialRequestForm.material_request_form_items[0]
                      .purchase_requisition.plant_maintenance_order &&
                    `${materialRequestForm.material_request_form_items[0].purchase_requisition.plant_maintenance_order.order_number} - ${materialRequestForm.material_request_form_items[0].purchase_requisition.plant_maintenance_order.description}`}
                </TableCell>
                <TableCell>
                  {materialRequestForm.vendor.fullvendorname}
                </TableCell>
                <TableCell>
                  {materialRequestForm.purchase_order
                    ? materialRequestForm.purchase_order.po_number
                    : materialRequestForm.create_purchase_order_job_id && (
                        <span>
                          <CircularProgress
                            color="success"
                            size={16}
                            sx={{ marginRight: "5px" }}
                          />
                          <span>Generating</span>
                        </span>
                      )}
                </TableCell>
                <TableCell align="center">
                  {materialRequestForm.filled ? (
                    <Tooltip title={i18next.t("yes")}>
                      <Icon
                        path={mdiClipboardCheck}
                        color={green["500"]}
                        size={1}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={i18next.t("no")}>
                      <Icon
                        path={mdiClipboardClock}
                        color={blue["500"]}
                        size={1}
                      />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={i18next.t("view")}>
                    <IconButton component={Link} to={materialRequestForm.id}>
                      <Icon path={mdiEye} size={0.8} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const MaterialRequestForms: FC = (): ReactElement => {
  const [tabOpen, setTabOpen] = useState("open");

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setTabOpen(() => newValue);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <h1>{i18next.t("materialRequestForm.title")}</h1>

      <TabContext value={tabOpen}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabOpen} onChange={handleTabChange}>
            <Tab label={i18next.t("materialRequestForm.open")} value="open" />
            <Tab
              label={i18next.t("materialRequestForm.finished")}
              value="finished"
            />
          </Tabs>
        </Box>

        <TabPanel value="open">
          <DataTab finished={false} />
        </TabPanel>
        <TabPanel value="finished">
          <DataTab finished />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default MaterialRequestForms;
