import { Quotation } from "../@types/services";
import api from "./api";

interface ListQuotationsParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  filterStatusName?: string;
  filterHasCreatedPurchaseOrderByQuotation?: boolean;
}

interface ListQuotationsIssuePurchaseOrderParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  valid?: boolean;
}

interface QuotationResponse {
  total_items: number;
  results: Quotation[] | null;
}

export const listQuotations = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  filterStatusName,
  filterHasCreatedPurchaseOrderByQuotation,
}: ListQuotationsParams): Promise<QuotationResponse> => {
  const response = await api.get<QuotationResponse>("/quotations", {
    baseURL: "/vbuyer/api/v2",
    params: {
      page,
      items_per_page: itemsPerPage,
      order,
      status_name: filterStatusName,
      has_create_purchase_order_by_quotation:
        filterHasCreatedPurchaseOrderByQuotation,
    },
  });

  return response.data;
};

export const getQuotation = async (id: number): Promise<Quotation> => {
  const response = await api.get<Quotation>(`/quotations/${id}`, {
    baseURL: "/vbuyer/api/v2",
  });

  return response.data;
};

export const listQuotationsIssuePurchaseOrder = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  valid = true,
}: ListQuotationsIssuePurchaseOrderParams): Promise<QuotationResponse> => {
  const response = await api.get<QuotationResponse>(
    "/quotations/issue_purchase_order",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        order,
        valid,
      },
    },
  );

  return response.data;
};

export const removeCreatePurchaseOrderByQuotation = async (
  id: number,
): Promise<void> => {
  const response = await api.delete<void>(
    `/quotations/${id}/remove_create_purchase_order_by_quotation`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};
