import { Box, Button } from "@mui/material";
import { FC, ReactNode } from "react"

interface ActionsProps {
  children: ReactNode;
}
const FilterActions: FC<ActionsProps> = ({ children }) => {
  return (
    <Box>
      {children}
    </Box>
  )
}

export default FilterActions;
