import { styled } from "@mui/material/styles";

export const EnvTag = styled("img")(() => ({
  position: "absolute",
  marginLeft: "-70px",
  width: "160px",
  transform: "rotate(-45deg)",
}));

export const Logo = styled("img")(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(1, 0),
  paddingLeft: "30px",
}));
