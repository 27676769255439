import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserAssignCondition from "../../../@types/services/UserAssignCondition";
import { fetchAssignConditionsUsersList } from "../../../services/assignConditions";

const AssignConditionsUsersTable: FC = (): ReactElement => {
  const [assignConditionsUsersList, setAssignConditionsUsersList] =
    useState<UserAssignCondition[]>();

  const handleFetchAssignConditionsUsersList = useCallback(async () => {
    const response = await fetchAssignConditionsUsersList();

    // Ordena os usuários, primeiro os que possuem alguma condição de atribuição, depois os que não possuem nenhuma.
    // Em cada subgrupo, ordena por ordem alfabética.
    const sortedData = response.results.sort(
      (a: UserAssignCondition, b: UserAssignCondition) => {
        const aHasConditions = a.assign_conditions.length > 0;
        const bHasConditions = b.assign_conditions.length > 0;

        if (aHasConditions && !bHasConditions) return -1;
        if (!aHasConditions && bHasConditions) return 1;
        return a.first_name.localeCompare(b.first_name);
      },
    );
    setAssignConditionsUsersList(sortedData);
    console.log(response.results);
  }, []);

  useEffect(() => {
    handleFetchAssignConditionsUsersList();
  }, []);

  return (
    <Paper
      sx={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "24px",
      }}
    >
      {assignConditionsUsersList ? (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{"Nome"}</TableCell>
              <TableCell>{"Assign Conditions"}</TableCell>
              <TableCell align="right">{"Editar"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignConditionsUsersList.map((user) => (
              <TableRow
                key={user.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                <TableCell
                  sx={{
                    gap: "8px",
                  }}
                >
                  {user.assign_conditions.map((assign_condition) => (
                    <Chip
                      key={assign_condition.id}
                      sx={{ margin: "2px" }}
                      label={assign_condition.name}
                    />
                  ))}
                </TableCell>
                <TableCell align="right">
                  <Link
                    to={`${user.id}/manage_user_assign_conditions`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Icon path={mdiPencil} size={1} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Paper>
  );
};

export default AssignConditionsUsersTable;
