import { Box } from "@mui/material";
import { FC, ReactElement } from "react";
import PageTitle from "../../components/atoms/PageTitle";
import AssignConditionsUsersTable from "../../components/molecules/AssignConditionsUsersTable";

const AssignConditions: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageTitle title="MM - Assign Conditions" />
      <Box>
        <AssignConditionsUsersTable />
      </Box>
    </Box>
  );
};

export default AssignConditions;
