import PurchaseGroup from "../@types/services/PurchaseGroup";
import api from "./api";

export type PurchaseGroupsFilter = {
  name: string;
  description: string;
};

interface PurchaseGroupsFetch {
  page?: number;
  itemsPerPage?: number;
  filters?: PurchaseGroupsFilter;
}

interface PurchaseGroupsResponse {
  total_items?: number;
  results: PurchaseGroup[];
}

export const fetchPurchaseGroups = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: PurchaseGroupsFetch): Promise<PurchaseGroupsResponse> => {
  try {
    const response = await api.get<PurchaseGroupsResponse>("/purchase_groups", {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching purchase groups:", error);
    throw error;
  }
};
