import Plant from "../@types/services/Plant";
import api from "./api";

export type PlantsFilter = {
  name: string;
  description: string;
};

interface PlantsFetch {
  page?: number;
  itemsPerPage?: number;
  filters?: PlantsFilter;
}

interface PlantsResponse {
  total_items?: number;
  results: Plant[];
}

export const fetchPlants = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: PlantsFetch): Promise<PlantsResponse> => {
  try {
    const response = await api.get<PlantsResponse>("/plants", {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching purchase groups:", error);
    throw error;
  }
};
