import { Box, Button, Toolbar, Typography } from "@mui/material";
import { FC, ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";

const Page404: FC = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          height: 300,
          maxWidth: 800,
          flexGrow: 1,
          p: 3,
          justifyContent: "center",
          display: "grid",
          alignItems: "center",
          minHeight: "50vh",
          borderRadius: "2em",
          // gridTemplateColumns: "repeat(3, 1fr)",
          gridAutoColumns: '1fr',
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `
            "header header header"
            "subheader subheader subheader"
            "main main main"
            ". go_back ."
          `,
        }}
      >
        <Typography
          variant="h1"
          sx={{ textTransform: "uppercase", gridArea: "header", textAlign: "center" }}
        >
          404
        </Typography>

        <Typography
          variant="h4"
          sx={{ textTransform: "uppercase", gridArea: "subheader", textAlign: "center" }}
        >
          🔎 Página não encontrada...
        </Typography>

        <Typography
          style={{ gridArea: "main", textAlign: "center" }}
        >
          A página que você está procurando pode ter sido removida, seu nome
          alterado ou está temporariamente indisponível.
        </Typography>

        <Button
          variant="contained"
          size="large"
          sx={{
            width: "100%",
            gridArea: "go_back",
            justifyContent: "space-around",
          }}
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
};

export default Page404;
